import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericBaseModalProps } from "../atomic/organisms/modal/generic-base-modal";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Modal Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
const initialState: GenericBaseModalProps = {
  show: false,
  isLoading: false,
  title: undefined,
  buttons: null,
  children: null,
};

export const modalSlice = createSlice({
  name: "modalState",
  initialState,
  reducers: {
    buildModal(state, action: PayloadAction<GenericBaseModalProps>) {
      Object.assign(state, action.payload);
      state.show = true;
    },
    updateModal(state, action: PayloadAction<GenericBaseModalProps>) {
      Object.assign(state, action.payload);
    },
    clearModal(state) {
      Object.assign(state, initialState);
    },
    openModal(state) {
      state.show = true;
    },
    closeModal(state) {
      state.show = false;
    },
    toggleModal(state) {
      state.show = !state.show;
    },
  },
});

export const {
  buildModal,
  updateModal,
  clearModal,
  openModal,
  closeModal,
  toggleModal,
} = modalSlice.actions;
