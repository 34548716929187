import { ReactNode } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Button from "../../atoms/button";
import { ReactComponent as CloseIcon } from "purple-rain/assets/icons/modal-close.svg";

/**
 * BaseModalProps defines the properties for the BaseModal component.
 *
 * @property {() => void} [onClose] - Function called to close the modal.
 * @property {() => void} [onCloseButtonClick] - Function called when the close button is clicked. This will call handleClose after execution.
 */
export type BaseModalProps = {
  title?: string;
  titleClassName?: string;
  show?: boolean;
  onClose?: () => void;
  onCloseButtonClick?: () => void;
  children?: ReactNode;
  buttons?: ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
} & ModalProps;

const BaseModal = ({
  title,
  titleClassName,
  children,
  buttons,
  onClose,
  headerClassName,
  bodyClassName,
  footerClassName,
  onCloseButtonClick,
  ...modalProps
}: BaseModalProps) => {
  const currentTitleClassName =
    titleClassName ||
    "mb-0 text-subtitle-01 text-md-headline-06 text-typography-surface-high-emphasis";

  return (
    <Modal onHide={onClose} centered {...modalProps}>
      {(title || onCloseButtonClick) && (
        <Modal.Header className={headerClassName}>
          {title && (
            <h4 className={currentTitleClassName} data-testid="modal-title">
              {title}
            </h4>
          )}
          {onCloseButtonClick && (
            <Button
              type="button"
              aria-label="Close"
              variant="ghost"
              size="sm"
              onClick={onCloseButtonClick}
            >
              <CloseIcon />
            </Button>
          )}
        </Modal.Header>
      )}
      <Modal.Body className={bodyClassName}>{children}</Modal.Body>
      {buttons && (
        <Modal.Footer className={footerClassName}>{buttons}</Modal.Footer>
      )}
    </Modal>
  );
};

export default BaseModal;
