import { t } from "i18next";
import React, { useState } from "react";
import PasswordValidationGroup from "../password-validation-form/password-validation-group";
import Button from "../../atoms/button";
import { handleValidation } from "../../../helpers/form-validation";

interface PasswordFormProps {
  email: string;
  eyebrow?: string;
  showEmailText?: boolean;
  saveButtonText: string;
  cancelButtonText: string;
  onSubmit: (isFormValid: boolean, password: string) => void;
  onDismiss: () => void;
}

const PasswordForm = ({
  email,
  eyebrow = "",
  showEmailText = false,
  saveButtonText,
  cancelButtonText,
  onSubmit,
  onDismiss,
}: PasswordFormProps) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = handleValidation(event);
    onSubmit(isFormValid, password);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="w-100">
      <div className="d-flex flex-column gap-3">
        <div>
          {eyebrow && (
            <p className="m-0 text-uppercase text-eyebrown fw-semibold box-title">
              {eyebrow}
            </p>
          )}
          <p className="m-0">
            {showEmailText ? `${t("global.email")}:` : ""} {email}
          </p>
        </div>

        <PasswordValidationGroup
          password={password}
          handlePassword={handlePasswordChange}
          isDarkBackground
        />

        <div className="d-flex flex-column flex-xl-row gap-3">
          <Button className="p-12px" type="submit" color="primary">
            {saveButtonText}
          </Button>
          <Button
            onClick={onDismiss}
            variant="link"
            size="md"
            className="text-white p-12px"
          >
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PasswordForm;
