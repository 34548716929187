const ConsentToObtainCreditReport = () => (
  <>
    <p className="mb-4">
      <span className="fw-bold">Your Consent.</span> By creating a Lakeview
      Powered by Fastlane account, you are providing written instructions in
      accordance with the Fair Credit Reporting Act and other applicable laws
      authorizing Lakeview Loan Servicing, LLC (“Lakeview”), Lakeview Household
      Insurance Solutions, LLC, Fastlane Financial Solutions, LLC., Fastlane
      Technology Solutions, LLC, and their respective affiliates, subsidiaries,
      and service providers (collectively, “we,” “us,” and “our”) to obtain
      consumer reports and related information about you at any time from one or
      more consumer reporting agencies and/or other third parties for any lawful
      purpose. Such lawful purposes include evaluating your credit information
      (i) to determine your eligibility for Products and Services in which you
      might be interested, (ii) when considering you for referrals and making
      referrals of Products or Services, (iii) in connection with processing any
      application you submit for a Product or Service, and (iv) for credit and
      risk modeling. In connection with any Product or Service you request, you
      also authorize the parties described above to contact any third party to
      verify information you have submitted in order to receive such Product or
      Service.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Withdrawing Consent.</span> Your consent applies
      as long as you have a Lakeview Powered by Fastlane account. To withdraw
      your consent, you must terminate your Lakeview Powered by Fastlane
      account. You may do so by contacting us at fastlanesupport@lakeview.com.
      Please note that withdrawing your consent will not affect any other
      consent you may have provided to us, a third-party service provider or
      third-party originating bank, or other third party to obtain consumer
      reports about you, such as in an application for credit regarding a loan
      or credit card agreement.
    </p>
  </>
);

export default ConsentToObtainCreditReport;
