import BaseModal from "../modal";
import { Button } from "../../atoms/button";
import { translate as t } from "../../../helpers/i18n";
import AfBAContent from "../afba/afba-content";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useEffect } from "react";

export interface AcceptTCModalProps {
  show: boolean;
  onCancel?: () => void;
  onAccept?: () => void;
  isLoading?: boolean;
}

const AcceptAfBAModal = ({
  show,
  onCancel,
  onAccept,
  isLoading,
}: AcceptTCModalProps) => {
  useEffect(() => {
    if (show) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "afba-conditions",
      });
    }
  }, [show]);

  const handleOnAccept = () => {
    if (typeof onAccept === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: "afba-conditions",
        button: "agree",
      });
      onAccept();
    }
  };

  const handleOnCancel = () => {
    if (typeof onCancel === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: "afba-conditions",
        button: "maybe later",
      });
      onCancel();
    }
  };
  return (
    <BaseModal
      show={show}
      onClose={handleOnCancel}
      onCloseButtonClick={handleOnCancel}
      fullscreen="md-down"
      dialogClassName="terms-modal-dialog"
      contentClassName="pb-3"
      bodyClassName="flex-grow-0 pt-5 pb-4 px-0 pt-md-0 px-md-40px px-xl-7 overflow-visible bg-white"
      footerClassName="px-4 pt-0 pb-3 px-md-40px px-xl-7 bg-white"
      headerClassName="d-none d-md-flex pb-1"
      backdropClassName="d-none d-md-block"
      buttons={
        <div className="d-flex flex-column w-100 flex-md-row-reverse gap-3">
          <Button
            type="button"
            color="primary"
            className="btn btn-primary"
            onClick={handleOnAccept}
            isLoading={!!isLoading}
          >
            {t("afba_modal.agree_button")}
          </Button>
          <Button variant="link" onClick={onCancel} type="button">
            {t("afba_modal.cancel_button")}
          </Button>
        </div>
      }
    >
      <div>
        <div className="d-md-none text-headline-26px mb-3 px-4 px-md-0">
          {t("afba_modal.mobile_title")}
        </div>
        <div className="d-none d-md-block text-headline-04 mb-3 px-4 px-md-0">
          {t("afba_modal.desktop_title")}
        </div>
        <p className="text-body-02 text-md-body-01 mb-4 mb-md-3 px-4 px-md-0">
          {t("afba_modal.content")}
        </p>
        <AfBAContent />
      </div>
    </BaseModal>
  );
};

export default AcceptAfBAModal;
