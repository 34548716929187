const ArbitrationAgreement = () => (
  <>
    <p className="mb-4">
      <span className="fw-bold">PLEASE READ THIS CAREFULLY.</span> BY AGREEING
      TO THIS ARBITRATION AGREEMENT YOU ACKNOWLEDGE THAT YOU HAVE READ IT
      CAREFULLY, AND UNDERSTAND THAT IT LIMITS YOUR RIGHTS IN THE EVENT OF A
      DISPUTE BETWEEN YOU AND US. BY AGREEING TO THIS ARBITRATION AGREEMENT, YOU
      WAIVE YOUR RIGHTS TO TRY ANY CLAIM IN COURT BEFORE A JUDGE OR JURY (EXCEPT
      FOR MATTERS THAT MAY BE TAKEN TO A SMALL CLAIMS COURT) AND TO BRING OR
      PARTICIPATE IN ANY CLASS OR OTHER REPRESENTATIVE ACTION. YOU UNDERSTAND
      THAT YOU HAVE THE RIGHT TO{" "}
      <span className="fw-bold">REJECT THIS PROVISION BY OPTING OUT</span>, AS
      PROVIDED BELOW.
    </p>
    <p className="mb-3">
      <span className="fw-bold">Definitions.</span> In this Arbitration
      Agreement:
    </p>
    <ol className="mb-4" type="a">
      <li>
        “You” and “your” mean the individual entering into this Arbitration
        Agreement, as well as any person claiming through such individual;
      </li>
      <li>
        “We” and “us” means Lakeview Loan Servicing, LLC (“Lakeview”), and their
        affiliates, subsidiaries, and service providers, which includes Fastlane
        Financial Solutions, LLC, its subsidiary Fastlane Technology Solutions,
        LLC, (“Fastlane”), and their affiliates, subsidiaries, and service
        providers;
      </li>
      <li>
        You and We are collectively referred to as the “Parties;” “Claim” means
        any dispute, claim, or controversy (whether based on contract, tort,
        intentional tort, constitution, statute, ordinance, common law, or
        equity, whether pre-existing, present, or future, and whether seeking
        monetary, injunctive, declaratory, or any other relief) arising from or
        relating to the Terms of Use or the relationship between us and you
        (including claims arising prior to or after the date of the Terms of
        Use, and claims that are currently the subject of purported class action
        litigation in which you are not a member of a certified class), and
        includes claims that are brought as counterclaims, cross claims, third
        party claims or otherwise, as well as disputes about the validity or
        enforceability of these Terms of Use or the validity or enforceability
        of this Arbitration Agreement (except the validity, enforceability, and
        effect of the No Class Action Proceedings and Public Injunctive Relief
        Requests paragraphs, which shall be determined by a court). Claim does
        not include a dispute relating to a residential mortgage loan or
        extension of credit under an open-end consumer credit plan secured by
        your principal dwelling. Claim does not include a claim by a covered
        borrower as defined in the Military Lending Act, 10 U.S.C. § 987. Claims
        filed by you or by us in a small claims court are not subject to
        arbitration, so long as the dispute remains in such court and advances
        only an individual claim for relief.
      </li>
    </ol>
    <p className="mb-4">
      <span className="fw-bold">Claim Notice.</span> Before starting a lawsuit
      or arbitration, the party electing to commence a proceeding must give the
      other party written notice of the Claim. The notice must include: (1) the
      name, telephone number, mailing address, and email address of the party
      seeking arbitration; (2) the Fastlane account number at issue; (3)
      reasonable detail of the Claim, including supporting facts; (4) the remedy
      sought and a good-faith calculation of the amount in controversy,
      expressed in United States Dollars; and (5) the original signature of the
      party making a Claim. You must send the notice in writing to Fastlane
      Technology Solutions, LLC, Attn: Compliance Department, 4425 Ponce De Leon
      Blvd., Ste. 4-032, Coral Gables, FL 33146. If we intend to make a Claim,
      we’ll notify you in writing at the most recent address we have for you in
      our files. The complaining party must give the other party a reasonable
      opportunity over the 30 days after notice is sent to resolve the Claim.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Claims Subject to Arbitration.</span> Any Claim
      shall be resolved, upon the election of either us or you, by binding
      arbitration administered by the American Arbitration Association (“AAA”)
      or JAMS, under the applicable arbitration rules of the administrator in
      effect at the time a Claim is filed (“Rules”). Subject to the terms of
      this Arbitration Agreement, either you or we may start an arbitration by
      giving written notice to the other party. This notice may be given before
      or after a lawsuit has been filed concerning the Claim and it may be given
      by papers filed in the lawsuit such as a motion to compel arbitration.
      AAA’s rules may be obtained from www.adr.org or 1-800-778-7879
      (toll-free). JAMS’ rules may be obtained from www.jamsadr.com or
      1-800-352-5267 (toll free). If both AAA and JAMS are for any reason unable
      to serve, then the parties may agree to a comparable arbitration
      administrator. If the parties are unable to agree, then either we or you
      may petition a court of competent jurisdiction to appoint an arbitrator.
      Any arbitration under this Arbitration Agreement will take place on an
      individual basis; class arbitrations and class actions are not permitted.
      If you file a claim, you may choose the administrator; if we file a claim,
      we may choose the administrator, but we agree to change to the other
      permitted administrator at your request (assuming that the other
      administrator is available). Claims will be arbitrated by a single,
      neutral arbitrator, who shall be a retired judge or a lawyer with at least
      ten years’ experience.
    </p>
    <p className="mb-4">
      <span className="fw-bold">No Class Action Proceedings.</span> YOU AND WE
      AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN OUR INDIVIDUAL
      CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
      REPRESENTATIVE PROCEEDING. Further, unless both you and we agree otherwise
      in writing, the arbitrator may not consolidate more than one person’s
      claims. The arbitrator shall have no power to arbitrate any Claims on a
      class action basis or Claims brought in a purported representative
      capacity on behalf of the general public, other borrowers, or other
      persons similarly situated. The validity, enforceability, and effect of
      this paragraph shall be determined exclusively by a court, and not by the
      administrator or any arbitrator. This paragraph does not apply to requests
      for public injunctive relief, which are addressed in the paragraph below
      entitled “Public Injunctive Relief Requests.”
    </p>
    <p className="mb-4">
      <span className="fw-bold">Jury Waiver and Limitation of Rights.</span> YOU
      AND WE AGREE THAT, BY ENTERING INTO THESE TERMS OF USE, THE PARTIES ARE
      EACH WAIVING THE RIGHT TO A TRIAL BY JURY. YOU AND WE ACKNOWLEDGE THAT
      ARBITRATION WILL LIMIT OUR LEGAL RIGHTS, INCLUDING, THE RIGHT TO A JURY
      TRIAL, THE RIGHT TO CONDUCT FULL DISCOVERY, AND THE RIGHT TO APPEAL
      (EXCEPT AS PERMITTED IN THIS ARBITRATION AGREEMENT OR UNDER THE FAA).
    </p>
    <p className="mb-4">
      <span className="fw-bold">Public Injunctive Relief Requests.</span> If you
      or we seek public injunctive relief as a remedy for any Claim (a “
      <span className="fw-bold">Public Injunctive Relief Request</span>”) you
      and we agree that the Public Injunctive Relief Request cannot be
      arbitrated. Instead, the Public Injunctive Relief Request shall be
      adjudicated by a court after all other Claims to be decided in arbitration
      under this Arbitration Agreement are resolved in arbitration. You and we
      agree to jointly request that the court stay the Public Injunctive Relief
      Request until after the remaining Claims have been finally resolved in
      arbitration, and that the parties will only seek to lift the stay and
      request that the court resolve the Public Injunctive Relief Request if an
      arbitrator finds that one of them is liable for a Claim for which public
      injunctive relief is an available remedy. The validity, enforceability,
      and effect of this paragraph shall be determined exclusively by a court,
      and not by any arbitration administrator or arbitrator.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Arbitration Costs.</span> We will pay all filing
      and administration fees charged by the administrator and arbitrator fees
      up to $1,000, we will consider your request to pay any additional
      arbitration costs, and we will pay all filing and administration fees that
      an arbitrator deems necessary to enforce this Arbitration Agreement. If an
      arbitrator issues an award in our favor, you will not be required to
      reimburse us for any fees we have previously paid to the administrator or
      for which we are responsible. If you receive an award from the arbitrator,
      we will reimburse you for any fees paid by you to the administrator or
      arbitrator. Each party shall bear its own attorney’s, expert’s and witness
      fees, which shall not be considered costs of arbitration; however, if a
      statute gives you the right to recover these fees, or fees paid to the
      administrator or arbitrator, then these statutory rights will apply in
      arbitration.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Arbitration Hearing and Decision.</span> Any
      in-person arbitration hearing will be held in the city with the federal
      district court closest to your residence, or in such other location as you
      and we may mutually agree. The arbitrator shall apply applicable
      substantive law consistent with the Federal Arbitration Act, 9 U.S.C. §
      1-16 (the “FAA”), and, if requested by either party, provide written
      reasoned findings of fact and conclusions of law. Except as provided in
      the “Public Injunctive Relief Requests” paragraph below, the arbitrator
      shall have the power to award any relief authorized under applicable law,
      including injunctive relief. Any appropriate court may enter judgment upon
      the arbitrator’s award. The arbitrator’s award shall be final and binding
      except that any party may appeal any award relating to a Claim for more
      than $100,000 or for injunctive relief to a three-arbitrator panel
      appointed by the arbitration administrator, which will reconsider de novo
      any aspect of the appealed award. The panel’s decision will be final and
      binding. In either event, you or we may seek to have the award vacated or
      confirmed and entered as a judgment in any court having jurisdiction.
      Unless applicable law provides otherwise, the appealing party will pay the
      appeal’s cost, regardless of its outcome. However, we will consider any
      reasonable written request by you for us to bear the cost.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Severability.</span> If any portion of this
      Arbitration Agreement is deemed invalid or unenforceable for any reason,
      it shall not invalidate the remaining portions of this Arbitration
      Agreement. However, if the No Class Action Proceedings paragraph or the
      Public Injunctive Relief Requests paragraph of this Arbitration Agreement
      is deemed invalid or unenforceable in whole or in part, then this entire
      Arbitration Agreement shall be deemed invalid and unenforceable. The terms
      of this Arbitration Agreement will prevail if there is any conflict
      between the Rules and this section.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Your Right to Reject Arbitration.</span> You
      understand that you may reject the provisions of this Arbitration
      Agreement, in which case neither us nor you will have the right to elect
      arbitration. Rejection of this Arbitration Agreement will not affect the
      remaining parts of the Terms of Use. To reject this Arbitration Agreement,
      you must send us written notice of your rejection within 60 days after the
      date after you agreed to these Terms of Use. You must include your name,
      address, and account number. The notice of rejection must be mailed to
      Fastlane Technology Solutions, LLC, Attn: Compliance Department, 4425
      Ponce De Leon Blvd., Ste. 4-032, Coral Gables, FL 33146. This is the only
      way that you can reject this Arbitration Agreement.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Applicability of the FAA and Survival.</span>{" "}
      You and we acknowledge and agree that the arbitration agreement set forth
      in this Arbitration Agreement is made pursuant to a transaction involving
      interstate commerce, and thus the FAA shall govern the interpretation and
      enforcement of this Arbitration Agreement. This Arbitration Agreement
      shall survive the termination of the Terms of Use.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Changes.</span> Notwithstanding anything to the
      contrary in the Terms of Use, the following terms shall apply to amendment
      of this Arbitration Agreement. You and we agree that we have the right to
      amend this Arbitration Agreement, and that if we make any amendment to
      this Arbitration Agreement (other than an amendment to any notice address
      or website link provided herein), that amendment shall be effective upon
      our provision of notice to you. Any change shall not apply to any Claim
      against us that accrued prior to the effective date of the change.
      Instead, the change shall apply to all other Claims governed by this
      Arbitration Agreement that have arisen or may arise between you and us. If
      you do not agree to these amended terms, you may reject the amended
      Arbitration Agreement and you will not be bound by it. To reject the
      amended terms, you must send us written notice of your rejection within 60
      days after the date we provided notice of the amendment. You must include
      your name, address, and account number. The notice of rejection must be
      mailed to Fastlane Technology Solutions, LLC, Attn: Compliance Department,
      4425 Ponce De Leon Blvd., Ste. 4-032, Coral Gables, FL 33146. This is the
      only way that you can reject amendments to this Arbitration Agreement.
    </p>
  </>
);

export default ArbitrationAgreement;
