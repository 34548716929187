const PrivacyPolicy = () => (
  <>
    <h6>Overview</h6>
    <p className="mb-4">
      This Privacy Policy explains how Lakeview Loan Servicing, LLC
      (“Lakeview”), Lakeview Household Insurance Solutions, LLC, Fastlane
      Financial Solutions, LLC, Fastlane Technology Solutions, LLC, and their
      respective affiliates, subsidiaries, and service providers (collectively,
      “we,” “us,” and “our”) collect, use, protect, and disclose personal
      information about consumers when you use “Fastlane” and “Lakeview Powered
      by Fastlane” brands, including, without limitation the Fastlane website
      (the “Website”), the Fastlane mobile application (the “Application”), and
      the Services provided by us through the Website and the Application (the
      “Services” as described in the Terms of Use). This Privacy Policy also
      explains any rights you may have about these practices.
    </p>
    <p className="mb-4">
      We may update this Privacy Policy, and our Federal Privacy Notice, from
      time to time. You can always view the most recent version of this Privacy
      Policy and/or Federal Privacy Notice on our Website. We will notify you of
      any changes as required by applicable law.
    </p>
    <p className="mb-4">
      In the event that you need to contact us about this Privacy Policy or our
      privacy practices, please contact us at fastlanesupport@lakeview.com.
    </p>
    <h6 className="mt-5 pt-2">Information Collection and Sharing Activities</h6>
    <p className="mb-3">
      <span className="fw-bold">
        What are the types of personal information we collect?{" "}
      </span>{" "}
      The following are examples of the types of personal information and other
      information we may collect about you:
    </p>
    <ul className="mb-4">
      <li>
        Your name and identifying information, such as your date of birth;
      </li>
      <li>
        Your contact information, such as your address, email address, and phone
        number;
      </li>
      <li>
        Financial and financial-related information, such as your income,
        employer, mortgage history and the amount of your mortgage, credit
        history;
      </li>
      <li>
        Information about your home or property, such as your address or
        location and property value;
      </li>
      <li>
        Technical information in connection with your use of the Website or
        Application, such as your device ID, the Internet Protocol (“IP”)
        address used to connect your device to the internet address, browser
        type and version, and operating system; and
      </li>
      <li>
        Information about your visit and your behavior on the Website or
        Application, such as the pages that you visited and your preferences.
      </li>
    </ul>
    <p className="mb-3">
      <span className="fw-bold">
        What are the sources from which we collect personal information about
        you?
      </span>{" "}
      We collect personal information and other information about you from a
      number of sources, including:
    </p>
    <ul className="mb-4">
      <li>
        Our affiliates such as Lakeview Household Insurance Solutions, LLC
        (“LHIS”) and our affiliates’ service providers;
      </li>
      <li>
        When you give us your personal information in order to receive products,
        services, or information, such as when you register for a user account,
        or contact us by phone, email or other means;
      </li>
      <li>
        Third parties, such as credit reporting agencies, identity verification
        service providers; and home valuation companies, and
      </li>
      <li>
        Cookies, web beacons, mobile application plug-ins, or other similar
        technologies.
      </li>
    </ul>
    <p className="mb-3">
      <span className="fw-bold">
        How do we use the information we collect about you?
      </span>{" "}
      We use the information collected about you in a number of ways, including
      but not limited to:
    </p>
    <ul className="mb-4">
      <li>Provide you with information about your mortgage and your home;</li>
      <li>
        Provide you with information about products and services that we think
        may be of interest to you;
      </li>
      <li>Engage in marketing, market research, and business development;</li>
      <li>
        Process your requests for information or another product or service
        offered through the Website or Application;
      </li>
      <li>
        Service products or services you obtain through the Website or
        Application or that you otherwise obtain from us or one of our
        affiliates;
      </li>
      <li>Communicate with you;</li>
      <li>Provide you customer support and respond to your inquiries;</li>
      <li>
        Detect, investigate and prevent potentially prohibited or illegal
        activities, such as fraud;
      </li>
      <li>Engage in risk profiling and other data analytics;</li>
      <li>
        Operate, improve, maintain, and ensure the security of the Website and
        Application;
      </li>
      <li>Comply with laws and regulations;</li>
      <li>
        Exercise or defend our legal rights or the legal rights of others; and
      </li>
      <li>
        Use in connection with any other legitimate business purpose permitted
        by applicable law.
      </li>
    </ul>
    <p className="mb-3">
      <span className="fw-bold">
        Who do we share information about you with?
      </span>{" "}
      We may share the personal information and other information we collect
      about you with:
    </p>
    <ul className="mb-4">
      <li>Our affiliates;</li>
      <li>Service providers;</li>
      <li>Third parties who provide products and services;</li>
      <li>Credit reporting agencies;</li>
      <li>
        Other companies in connection with a merger, acquisition, or the sale of
        assets;
      </li>
      <li>
        Third parties, including governmental authorities and law enforcement,
        to protect or defend our legal rights or the legal rights of others,
        protect against fraud; and comply with applicable law, subpoena, or
        legal process;
      </li>
      <li>Third parties in an anonymized and aggregated manner; and</li>
      <li>Other third parties as permitted by applicable law. </li>
    </ul>
    <h6 className="mt-5 pt-2">Cookies and Do Not Track Signals</h6>
    <p className="mb-4">
      <span className="fw-bold">Cookies.</span> Cookies are pieces of
      information stored directly on a computer or mobile application you are
      using. We or our service providers may place cookies, or similar files, on
      your device, including for security purposes, to facilitate navigation,
      and to personalize your experience while using the Website and
      Application. Cookies allow us to collect technical and navigational
      information, such as browser type, time spent on a website or mobile
      application, and pages visited. Cookies allow us to select which
      advertisements or offers are most likely to appeal to you and display them
      to you. We may also use cookies in online advertising to track responses
      and may use cookies or other files to track your use of other websites.
      Cookies and other technologies provide us the capability to monitor the
      use of the Website and Application so we can continually improve the
      design and functionality to better serve you.
    </p>
    <p className="mb-4">
      If you do not accept these cookies, you may experience some inconvenience
      in your use of some online options. We use cookies to display information
      more effectively to you and to gather data about the usage of the Website
      and Application.
    </p>
    <p className="mb-4">
      At times, we may also use other technologies to capture customer specific
      data to help understand how customers use the Website and Application and
      to assist us with resolving customers’ questions regarding the use of the
      Website and Application.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Do-Not-Track Signals.</span> Many web browsers
      possess a do-not-track feature that lets your browser tell websites you
      visit that you do not want to have your online activities tracked. At this
      time, the Website does not respond to browser do-not-track signals.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Security.</span> To protect your personal
      information from unauthorized access, we use security and safeguarding
      measures that comply with federal and state law. These measures include
      but are not limited to computer and electronic safeguards and secured
      files and buildings. However, you acknowledge that no security measure or
      modality of data transmission over the Internet can be guaranteed to be
      100% secure. You should not expect that any personal information provided
      to us through the Website or Application is free from the risk posed by
      unauthorized access.
    </p>
    <p className="mb-4">
      If you are visiting the Website, your Internet session should be encrypted
      if your security-enabled browser is connected using the Secure Hypertext
      Transport Protocol. URL strings beginning with “HTTPS://” instead of the
      usual “HTTP://” indicate that the secure protocol is in effect. Your
      internet browser may also show an icon indicating that the Website is
      considered secure.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Minors.</span> You must be 18 years of age or
      older to register as an account user and receive services through the
      Website and Application. The Website and Application are not directed at
      people under 18 years of age and we do not knowingly collect any
      information about such persons.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Changing Your Information.</span> If you would
      like to review and request changes to the personally identifiable
      information that we use as part of servicing your loan, please contact
      your loan servicer directly. If you would like to review and request
      changes to the personally identifiable information that you have provided
      to us in your interactions with the Lakeview Powered by Fastlane website,
      please reach out to fastlanesupport@lakeview.com.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Call Monitoring.</span> You consent to the
      recording and monitoring of any call for quality assurance, training, risk
      management, collection purposes, or any other legitimate purpose, that you
      place to us (or our service providers) or that we (or our service
      providers) place to you.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Consent to Receive Email Communications.</span>{" "}
      You consent to receiving emails at any email address you provide to us,
      including marketing-related messages. You may opt out of receiving
      marketing emails by utilizing the “unsubscribe” functionality preferences
      included in those emails.
    </p>
    <p className="mb-4">
      <span className="fw-bold">State Privacy Disclosures.</span> Some states
      provide certain rights concerning your personal information, unless an
      exception applies. These exceptions include information subject to federal
      privacy laws, specifically the federal Gramm-Leach-Bliley Act (“GLBA”) and
      Fair Credit Reporting Act (“FCRA”), or entities that are subject to the
      GLBA. In addition to these exceptions, state privacy laws do not consider
      information to be personal information if it cannot be reasonably linked
      to any consumer or household. The information that Lakeview or its
      affiliates collect, uses, and shares may be exempt from state privacy laws
      as it is subject to the GLBA, subject to the FCRA, it is not deemed to be
      personal information, or Lakeview is subject to the GLBA.
    </p>
  </>
);

export default PrivacyPolicy;
