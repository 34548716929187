import { translate as t } from "../../../helpers/i18n";
import { handleLogIn, handleLogOut } from "../../../helpers/auth";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import Button from "../../../atomic/atoms/button";
import { cx } from "class-variance-authority";

type Props = {
  className?: string;
  buttonVariant?: "link" | "solid" | "ghost" | "outline" | null | undefined;
};

/** Button for login used in the header and hamburger menus  */
export const HeaderLoginLink = ({ className }: Props) => {
  return (
    <Button
      className={cx("btn-primary", className)}
      data-testid="nav-bar-login-btn"
      onClick={() => handleLogIn()}
    >
      {t("app.log_in")}
    </Button>
  );
};

/** Button for logout used in the header, hamburger menus and Sign Out Modals  */
export const LogoutLink = ({ className, buttonVariant = "solid" }: Props) => {
  const {
    account: { status: accountStatus },
    property: { status: propertyStatus },
  } = useSelector((state: RootState) => state);

  const isLoading = accountStatus === "loading" || propertyStatus === "loading";

  const handleNormalLogout = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "header",
      button: "logout",
      withConfirmationModal: false,
    });

    handleLogOut();
  };

  return (
    <Button
      className={cx("border text-black", className)}
      variant={buttonVariant}
      onClick={handleNormalLogout}
      disabled={isLoading}
      aria-disabled={isLoading}
    >
      {t("layout.header.logout")}
    </Button>
  );
};

/**
 * Button Shown on the header and menus when the user is authenticated: hide the buttons
 */
export const AuthenticatedButton = ({ className, ...otherProps }: Props) => {
  return (
    <LogoutLink
      {...otherProps}
      className={cx("btn-primary text-white", className)}
    />
  );
};
