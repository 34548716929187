import { ReactNode } from "react";

interface ContentBlockOverlayProps {
  children: ReactNode;
  className?: string;
  shouldBlockContent?: boolean;
}
const ContentBlockOverlay = ({
  children,
  className,
  shouldBlockContent,
}: ContentBlockOverlayProps) => {
  return (
    <div
      className={`position-relative ${className} ${
        shouldBlockContent ? "pe-none user-select-none" : ""
      }`}
    >
      {/* Overlay */}
      {!!shouldBlockContent && (
        <div className="content-block-overlay bg-typography-surface-disabled w-100 h-100 position-absolute z-3" />
      )}

      {/* Blocked content */}
      {children}
    </div>
  );
};

export default ContentBlockOverlay;
