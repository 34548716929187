import { useEffect, useRef, useState } from "react";
import mixpanel, { Mixpanel } from "mixpanel-browser";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { useSearchParams } from "react-router-dom";
import { getFastlaneVersion } from "../helpers/fastlane-info";
import {
  mixpanelInit,
  checkDisableMixpanel,
  trackUserId,
  trackPageView,
} from "analytics";

const isMixpanelDisabled =
  process.env.REACT_APP_MIXPANEL_UPLOADS_DISABLED === "true";

const loaded = (mixpanel: Mixpanel) => {
  mixpanel.register({
    dashboard_version: getFastlaneVersion(),
  });
};

mixpanelInit(
  mixpanel,
  process.env.REACT_APP_MIXPANEL_TOKEN as string,
  process.env.REACT_APP_MIXPANEL_API_HOST as string,
  process.env.REACT_APP_MIXPANEL_DEBUG_MODE as string,
  loaded
);

// Initializes the user and sets up pageview tracking
export const useTrackers = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const previousLocation = useRef<string>();
  const { user: userState } = useSelector((state: RootState) => state);
  const { initialized: isUserInitialized, user: userData } = userState;

  const [isUserIdentified, setIsUserIdentified] = useState(false);

  const isUserAuthenticated = isUserInitialized && !!userData;
  const isUserUnauthenticated = isUserInitialized && !userData;

  useEffect(() => {
    checkDisableMixpanel(
      mixpanel,
      process.env.REACT_APP_MIXPANEL_UPLOADS_DISABLED as string
    );
  }, []);

  useEffect(() => {
    if (isUserAuthenticated) {
      if (!isMixpanelDisabled) {
        // Set Mixpanel user only if mixpanel is enabled
        mixpanel.identify(userData?.id);
      }

      // Set user name if it was not already set
      trackUserId(mixpanel, userData?.id);

      // Set tracking flag to enable pageview tracking after login
      setIsUserIdentified(true);
    }
  }, [userData, isUserAuthenticated]);

  // Listen for location changes
  useEffect(() => {
    // can track if:
    // * user is authenticated and mixpanel.identify finished
    // * user is not authenticated
    // * Statsig is initialized as well
    const userIdentified = isUserAuthenticated && isUserIdentified;
    const canTrack = userIdentified || isUserUnauthenticated;

    // Avoid tracking if the user loads the same page
    if (canTrack && location.pathname !== previousLocation.current) {
      previousLocation.current = location.pathname;

      // Get source parameter
      const source = searchParams.get("source");
      const sourceProperty = source ? { source } : {};

      trackPageView(mixpanel, location.pathname, sourceProperty);
    }
  }, [
    isUserAuthenticated,
    isUserUnauthenticated,
    location,
    isUserIdentified,
    searchParams,
  ]);
};
