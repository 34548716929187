import { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import Collapse from "react-bootstrap/Collapse";
import Overlay from "react-bootstrap/Overlay";
import { ReactComponent as CloseIconSVG } from "purple-rain/assets/close-icon.svg";
import { ReactComponent as HamburgerSVG } from "purple-rain/icons/hamburger.svg";
import { ReactComponent as ChevronUpSVG } from "purple-rain/icons/chevron-up.svg";
import { ReactComponent as ChevronDownSVG } from "purple-rain/icons/chevron-down.svg";
import { translate as t } from "../../../helpers/i18n";
import { pages } from "../../../helpers/pages";
import { RootState } from "../../../stores";
import { AuthenticatedButton, HeaderLoginLink } from "./header-auth";
import {
  HeaderProductMenuItem,
  HOME_EQUITY_LOAN_TRANSLATION_KEY,
  PRODUCT_LIST,
} from "../../../helpers/constants";
import { EXPERIMENTS, useABTest } from "../../../hooks/use-ab-test";
import { experimentManualExposure } from "../../../helpers/experiment-exposure";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";

const MIXPANEL_MODULE_NAME = "header";

const trackProductClick = (productMenuItem: HeaderProductMenuItem) => {
  const link = `${window.location.origin}${productMenuItem.path}`;

  mixpanel.track(MIXPANEL_EVENTS.CLICK, {
    module: MIXPANEL_MODULE_NAME,
    button: productMenuItem.mixpanelButtonName,
    link,
  });
};

// Dropdown Menu displayed on the header for desktop resolutions
export const ProductsMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useSelector((state: RootState) => state);
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);

  // Ref for the product menu
  const productMenu = useRef(null);

  const handleToggleMenu = useCallback(
    () => setShowMenu(!showMenu),
    [showMenu]
  );
  const handleCloseMenu = useCallback(
    (translationKey?: string) => {
      const experimentConditionToMakeExposure =
        groupName !== "None" &&
        translationKey === HOME_EQUITY_LOAN_TRANSLATION_KEY;
      experimentManualExposure(
        EXPERIMENTS.TAVANT_DIGITAL_HELOAN,
        groupName,
        ["Control1", "Treatment1"],
        experimentConditionToMakeExposure
      );
      setShowMenu(false);
    },
    [groupName]
  );

  const homeUrl = user.user ? pages.home : pages.index;

  const handleProductClick = (productMenuItem: HeaderProductMenuItem) => {
    trackProductClick(productMenuItem);
    handleCloseMenu(productMenuItem.translationKey);
  };

  return (
    <>
      {/* Menu bar */}
      <div className="d-none d-md-flex px-lg-7 ps-md-5 align-items-center">
        <Link
          to={homeUrl}
          onClick={() => handleCloseMenu()}
          className="text-body-01 fw-semibold me-5 menu-main-link text-nowrap"
        >
          <span className="link-hover">{t("layout.header.links.home")}</span>
        </Link>
        <button
          className="btn btn-link text-capitalize text-body-01 text-black fw-semibold p-0 d-md-inline d-none menu-main-link me-5 text-nowrap"
          onClick={handleToggleMenu}
        >
          <span className="link-hover link-focus">
            {t("layout.header.links.products")}
          </span>
          <span className="ms-2">
            {showMenu ? <ChevronUpSVG /> : <ChevronDownSVG />}
          </span>
        </button>
        <Link
          to={pages.resourceCenter}
          onClick={() => handleCloseMenu()}
          className="text-body-01 text-black fw-semibold me-5 menu-main-link text-nowrap"
        >
          <span className="link-hover">
            {t("layout.header.links.resource_hub")}
          </span>
        </Link>
      </div>

      {/* Collapsible menu container */}
      <div
        ref={productMenu}
        className="position-absolute menu-dropdown w-100 start-0"
      />

      {/* Collapsible menu content */}
      <Overlay
        target={productMenu}
        container={productMenu}
        containerPadding={-5}
        show={showMenu}
        onHide={() => handleCloseMenu()}
        placement="bottom"
        rootClose
        rootCloseEvent="click"
        transition={Collapse}
      >
        <div
          id="products-menu"
          className="bg-primary-01 menu-dropdown-content w-100"
        >
          <div className="container-lg">
            <div className="gap-3 py-5 d-none d-md-flex justify-content-center flex-wrap menu-dropdown-link-list">
              {PRODUCT_LIST(isHeloanHomeEquityLoanActive).map((product) => (
                <Link
                  to={product.path}
                  onClick={() => handleProductClick(product)}
                  key={product.translationKey}
                  className="col h-100 text-black text-decoration-none menu-link rounded px-3 pt-2 pb-3"
                >
                  <div className="menu-title fw-semibold">
                    {t(`layout.products_list.${product.translationKey}.title`)}
                  </div>
                  <div className="text-body-02">
                    {t(
                      `layout.products_list.${product.translationKey}.description`
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

// Hamburger menu displayed on the header for mobile resolutions
export const HamburgerMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user: userData } = useSelector((state: RootState) => state);
  const { user } = userData;
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);

  const handleCloseMenu = useCallback(
    (translationKey?: string) => {
      experimentManualExposure(
        EXPERIMENTS.TAVANT_DIGITAL_HELOAN,
        groupName,
        ["Control1", "Treatment1"],
        groupName !== "None" &&
          translationKey === HOME_EQUITY_LOAN_TRANSLATION_KEY
      );
      setShowMenu(false);
    },
    [groupName]
  );

  const handleProductClick = (productMenuItem: HeaderProductMenuItem) => {
    trackProductClick(productMenuItem);
    handleCloseMenu(productMenuItem.translationKey);
  };

  const handleShow = useCallback(() => setShowMenu(true), []);

  const homeUrl = user ? pages.home : pages.index;

  return (
    <>
      <button
        className="btn btn-link text-black border-0 p-0 me-3 me-md-4 d-inline d-md-none"
        onClick={handleShow}
        aria-label={t("layout.header.hamburger.label")}
      >
        <HamburgerSVG width={24} height={24} />
      </button>
      <Offcanvas
        placement="start"
        show={showMenu}
        onHide={() => handleCloseMenu()}
        className="bg-gradient offcanvas-md d-md-none"
        backdropClassName="d-md-none"
      >
        <Offcanvas.Header className="justify-content-start py-2 px-4">
          <button
            className="btn btn-link text-black px-1 py-2"
            onClick={() => handleCloseMenu()}
          >
            <CloseIconSVG color="white" width={24} height={24} />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-md-none px-4 pt-3 pb-5">
          <div className="h-100 d-flex flex-column justify-content-between">
            {/* Links section */}
            <div>
              <div className="mt-0">
                <Link
                  to={homeUrl}
                  onClick={() => handleCloseMenu()}
                  className="text-body-01 text-white text-decoration-none d-block menu-link rounded p-3 fw-semibold"
                >
                  {t("layout.header.links.home")}
                </Link>
              </div>
              <div className="text-white py-3">
                <hr className="opacity-100 m-0" />
              </div>
              <div className="d-flex flex-column align-items-start text-body-01">
                {PRODUCT_LIST(isHeloanHomeEquityLoanActive).map((product) => (
                  <Link
                    to={product.path}
                    onClick={() => handleProductClick(product)}
                    key={product.translationKey}
                    className="w-100 d-block text-white text-decoration-none menu-link rounded p-3 fw-semibold"
                  >
                    {t(`layout.products_list.${product.translationKey}.title`)}
                  </Link>
                ))}
              </div>
              <div className="text-white py-3">
                <hr className="opacity-100 m-0" />
              </div>
              <div className="mt-0">
                <Link
                  to={pages.resourceCenter}
                  onClick={() => handleCloseMenu()}
                  className="text-body-01 text-white text-decoration-none d-block menu-link rounded p-3 fw-semibold"
                >
                  {t("layout.header.links.resource_hub")}
                </Link>
              </div>
            </div>
            {/* Login/Logout section */}
            <div>
              {user ? (
                <AuthenticatedButton className="w-100 text-white" />
              ) : (
                <HeaderLoginLink className="w-100" />
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
