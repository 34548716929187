// Include our external dependencies.
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { cashOutPages, pages } from "../../helpers/pages";
import Footer from "./footer";
import Toasts from "./toasts";
import Banners from "./banners";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../stores";
import Loader from "../../components/loader";
import Header from "./header";
import { handleLogIn, LoginQueryParams } from "../../helpers/auth";
import SSOBanner from "../../atomic/organisms/sso-banner";
import ContentBlockOverlay from "./content-block-overlay";
import {
  AFBA_VERSION,
  CASH_OUT_SESSION_STORAGE_KEYS,
} from "../../helpers/constants";
import { useSSOValues } from "../../hooks/use-sso-values";
import { Suspense, useEffect, useState } from "react";
import {
  getCashOutFullPath,
  getTermsVersions,
  isMajorVersionDifferent,
  lazyWithRefresh,
  shouldRedirectToTavant,
  shouldShowProfilePage,
} from "../../helpers/utils";
import { loadTavantRedirectUrl } from "../../stores/digital-account-creation-slice";
import GenericBaseModal from "../../atomic/organisms/modal/generic-base-modal";

const TermsAndConditionsModal = lazyWithRefresh(
  () => import("../../atomic/organisms/terms-and-conditions-modal"),
  "terms-and-conditions-modal"
);

type Props = {
  layoutType: LayoutType;
};

/**
 * Determines the type of layout to load
 * * Protected layouts are for pages that require login
 * * Unprotected layouts are for pages that you can only access when logged out (main page/sign up)
 * * Global layouts are for pages that can be accessed both logged in and out (terms and conditions/ privacy policy)
 */
export type LayoutType = "protected" | "unprotected" | "global";

const Layout = ({ layoutType }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showAcceptAfBAModal, setShowAcceptAfBAModal] =
    useState<boolean>(false);
  const [shouldBlockContent, setShouldBlockContent] = useState(false);
  const { user, status } = useSelector((state: RootState) => state.user);
  const { showSSOBanner } = useSelector((state: RootState) => state.ssoUser);
  const { showTavantBanner } = useSelector((state: RootState) => state.tavant);
  const modalState = useSelector((state: RootState) => state.modalState);
  const [searchParams] = useSearchParams();
  const { isPendingFastlaneAccount, hasDismissedAfBAModal } = useSSOValues();

  const isProtectedPage = layoutType === "protected";
  const isGlobalPage = layoutType === "global";
  const isUnprotectedPage = layoutType === "unprotected";

  const [isLoading, setIsLoading] = useState(
    user && shouldRedirectToTavant(user.tags)
  );

  const { userTermsVersion, latestTermsVersion } = getTermsVersions(user);

  const showTermsAndConditionsModal =
    !isPendingFastlaneAccount &&
    user &&
    isMajorVersionDifferent(latestTermsVersion, userTermsVersion);

  useEffect(() => {
    if (
      user &&
      user?.afba_version &&
      isMajorVersionDifferent(AFBA_VERSION, user.afba_version) &&
      !hasDismissedAfBAModal &&
      !showTermsAndConditionsModal &&
      !showSSOBanner &&
      !showTavantBanner
    ) {
      setShowAcceptAfBAModal(true);
    } else {
      setShowAcceptAfBAModal(false);
    }
  }, [
    user,
    user?.afba_version,
    hasDismissedAfBAModal,
    showSSOBanner,
    showTermsAndConditionsModal,
    showTavantBanner,
  ]);

  useEffect(() => {
    if (
      showAcceptAfBAModal ||
      showSSOBanner ||
      showTermsAndConditionsModal ||
      showTavantBanner
    ) {
      setShouldBlockContent(true);
    } else {
      setShouldBlockContent(false);
    }
  }, [
    showAcceptAfBAModal,
    showSSOBanner,
    showTermsAndConditionsModal,
    showTavantBanner,
  ]);

  useEffect(() => {
    if (user && shouldRedirectToTavant(user.tags)) {
      dispatch(loadTavantRedirectUrl())
        .unwrap()
        .then((response) => {
          const url = response?.url;

          if (url) {
            window.open(url, "_self");
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error("Failed to redirect", err);
        });
    }
  }, [dispatch, user]);

  /* Accessing a protected page */
  if (isProtectedPage && !user) {
    /** If there was an error loading the user in a protected page, redirect to the error page */
    if (status === "failed") {
      return <Navigate to={pages.error} />;
    } else {
      /** if not logged in and accessing a protected route, redirect to login page */
      const params: LoginQueryParams | undefined =
        (searchParams.get("email-verification") as LoginQueryParams) ??
        undefined;

      handleLogIn(params);
    }
  }

  /** if started the cash-out flow process and signed in, redirect back to the cash-out flow */
  if (
    sessionStorage.getItem(CASH_OUT_SESSION_STORAGE_KEYS.SIGN_IN) === "true"
  ) {
    return <Navigate to={getCashOutFullPath(cashOutPages.steps.signIn)} />;
  }

  /** if logged in and accessing an unprotected route, redirect to home page */
  if (isUnprotectedPage && user) {
    return <Navigate to={pages.home} />;
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Header
        displayAuthInfo={isProtectedPage || isGlobalPage}
        showProfileLink={shouldShowProfilePage(user)}
      />
      {showTermsAndConditionsModal && (
        <Suspense fallback={null}>
          <TermsAndConditionsModal isUpdatedTermsAndConditions />
        </Suspense>
      )}
      {showAcceptAfBAModal && !showTermsAndConditionsModal && (
        <Suspense fallback={null}>
          <TermsAndConditionsModal
            showOnlyAfBA
            closeOnlyAfBa={() => setShowAcceptAfBAModal(false)}
          />
        </Suspense>
      )}
      <Banners />
      <SSOBanner />
      <div className="flex-grow-1 bg-white position-relative">
        <ContentBlockOverlay
          className="h-100"
          shouldBlockContent={shouldBlockContent}
        >
          <Outlet />
        </ContentBlockOverlay>
      </div>
      <Footer />
      <Toasts />
      <GenericBaseModal {...modalState} />
    </>
  );
};

export default Layout;
