// Include our external dependencies.
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Redirect } from "react-router6-redirect";
import { pages } from "./helpers/pages";
import "./sass/styles.scss";
import Loader from "./components/loader";
import Layout from "./modules/layout";
import { useTrackers } from "./hooks/use-trackers";
import useSessionTimer from "./hooks/use-session-timer";
import useScrollRestore from "./hooks/use-scroll-restore";
import { useMonitoring } from "./hooks/use-monitoring";
import { useSession } from "./hooks/use-session";
import { lazyWithRefresh, shouldShowProfilePage } from "./helpers/utils";
import { CASH_OUT_BASE_URL } from "./helpers/constants";
import useExperimentAnalytics from "./hooks/use-experiments-analytics";
import useDeepLink from "./hooks/use-deep-link";
import { FEATURE_GATES, useFeatureGate } from "./hooks/use-feature-gate";
import { EXPERIMENTS, useABTest } from "./hooks/use-ab-test";
import { RootState } from "./stores";
import { useSelector } from "react-redux";

const SSOError = lazyWithRefresh(
  () => import("./modules/sso-error"),
  "sso-error"
);
const HomeValue = lazyWithRefresh(
  () => import("./modules/home-value"),
  "home-value"
);
const NotFound = lazyWithRefresh(
  () => import("./modules/not-found"),
  "not-found"
);
const Profile = lazyWithRefresh(() => import("./modules/profile"), "profile");
const SignUp = lazyWithRefresh(() => import("./modules/sign-up"), "sign-up");
const UnauthenticatedDash = lazyWithRefresh(
  () => import("./modules/unauthenticated-dash"),
  "unauthenticated-dash"
);
const TermsAndConditions = lazyWithRefresh(
  () => import("./modules/terms-and-conditions"),
  "terms-and-conditions"
);
const TermsAndConditionsDashboard = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-dashboard"),
  "terms-and-conditions-dashboard"
);
const TermsAndConditionsDigital = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-digital"),
  "terms-and-conditions-digital"
);
const CashOutRefinance = lazyWithRefresh(
  () => import("./modules/products/cash-out-refinance"),
  "cash-out-refinance"
);
const GetAMortgage = lazyWithRefresh(
  () => import("./modules/products/get-a-mortgage"),
  "get-a-mortgage"
);

const HomeEquityLoan = lazyWithRefresh(
  () => import("./modules/products/home-equity-loan"),
  "home-equity-loan"
);

const RateTermRefinance = lazyWithRefresh(
  () => import("./modules/products/rate-term-refinance"),
  "rate-term-refinance"
);
const ErrorPage = lazyWithRefresh(() => import("./modules/error"), "error");
const FrequentlyAskedQuestions = lazyWithRefresh(
  () => import("./modules/faq"),
  "faq"
);

const UnverifiedEmail = lazyWithRefresh(
  () => import("./components/unverified-email"),
  "unverified-email"
);
const ResourceCenter = lazyWithRefresh(
  () => import("./modules/resource-center"),
  "resource-center"
);
const ResourceCenterArticle = lazyWithRefresh(
  () => import("./modules/resource-center/articles"),
  "articles"
);
const EmptyLayout = lazyWithRefresh(
  () => import("./modules/layout/empty-layout"),
  "empty-layout"
);
const SSORejection = lazyWithRefresh(
  () => import("./modules/sso-rejection"),
  "sso-rejection"
);
const HeaderlessLayout = lazyWithRefresh(
  () => import("./modules/layout/headerless-layout"),
  "headerless-layout"
);
const NpsPage = lazyWithRefresh(() => import("./modules/nps"), "nps");
const AdaAccessibilityPage = lazyWithRefresh(
  () => import("./modules/ada-accessibility"),
  "ada-accessibility"
);
const CashOutRoutes = lazyWithRefresh(
  () => import("./modules/cash-out-loan-quote/helpers/cash-out-routes"),
  "cash-out-routes"
);
const DigitalAccountLayout = lazyWithRefresh(
  () => import("./modules/digital-account-creation/components/main-layout"),
  "digital-account-layout"
);
const DigitalAccountCreation = lazyWithRefresh(
  () => import("./modules/digital-account-creation/pages/index"),
  "digital-account-creation"
);
const HomeRenovationCalculatorPage = lazyWithRefresh(
  () => import("./modules/home-renovation-calculator"),
  "home-renovation-calculator"
);

const App: React.FC = () => {
  /**
   * Hooks that require the app (redux or statsig) to be initialized first are called below
   */

  // Initialize DatadogRUM
  useMonitoring();

  // Session timers
  useSessionTimer();

  // Scroll restoration
  useScrollRestore();

  // Initialize trackers
  useTrackers();

  // Initialize session handling
  useSession();

  // Unpack Redux, global data, and types
  useExperimentAnalytics();

  // DeepLinking validation
  useDeepLink();

  const isDigitalAccountCreationEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_DIGITAL_ACCOUNT_CREATION
  );
  const { isExperimentAvailable: isTavantDigitalHeloanEnabled } = useABTest(
    EXPERIMENTS.TAVANT_DIGITAL_HELOAN
  );

  const isKukunCostCalculatorGate = useFeatureGate(
    FEATURE_GATES.ENABLE_KUKUN_COST_CALCULATOR
  );

  const { user } = useSelector((state: RootState) => state.user);

  return (
    <div
      className="app-component bg-white d-flex flex-column h-auto fade-in"
      data-component="App"
    >
      <Suspense fallback={<Loader />}>
        {/* Core Routing */}
        <Routes>
          {/* Headerless Layout - Account Creation */}
          <Route element={<HeaderlessLayout />}>
            <Route path={pages.signup} element={<SignUp />} />
            <Route path={`${pages.signup}/:step`} element={<SignUp />} />
          </Route>

          {/* Empty layout */}
          <Route element={<EmptyLayout />}>
            <Route path={pages.ssoRejection} element={<SSORejection />} />
            <Route path={pages.ssoError} element={<SSOError />} />
          </Route>

          {/* Protected Routes */}
          <Route element={<Layout layoutType="protected" />}>
            {shouldShowProfilePage(user) && (
              <Route path={pages.profile} element={<Profile />} />
            )}
            <Route path={pages.home} element={<HomeValue />} />
            {isKukunCostCalculatorGate && (
              <Route
                path={pages.homeRenovationCalculator}
                element={<HomeRenovationCalculatorPage />}
              />
            )}
          </Route>

          {/* Unprotected Routes */}
          <Route element={<Layout layoutType="unprotected" />}>
            <Route path={pages.index} element={<UnauthenticatedDash />} />
          </Route>

          {/* Digital Account Creation */}
          <Route element={<DigitalAccountLayout />}>
            <Route
              path={pages.digitalAccountCreation}
              element={<DigitalAccountCreation />}
            />
          </Route>

          <Route path={`${CASH_OUT_BASE_URL}/*`} element={<CashOutRoutes />} />

          {/* Global Routes */}
          <Route element={<Layout layoutType="global" />}>
            <Route
              path={pages.termsConditions}
              element={<TermsAndConditions />}
            />
            {isDigitalAccountCreationEnabled && (
              <>
                <Route
                  path={pages.termsConditionsDashboard}
                  element={<TermsAndConditionsDashboard />}
                />
                <Route
                  path={pages.termsConditionsDigital}
                  element={<TermsAndConditionsDigital />}
                />
              </>
            )}

            <Route
              path={pages.frequentlyAskedQuestions}
              element={<FrequentlyAskedQuestions />}
            />

            {/* Auth0 - unverified email */}
            <Route
              path={pages.needToVerifyEmail}
              element={<UnverifiedEmail />}
            />

            {/* PDP Pages */}
            <Route
              path={pages.products.cashOutRefinance}
              element={<CashOutRefinance />}
            />
            <Route
              path={pages.products.getAMortgage}
              element={<GetAMortgage />}
            />
            <Route
              path={pages.products.rateTermRefinance}
              element={<RateTermRefinance />}
            />

            {isTavantDigitalHeloanEnabled && (
              <Route
                path={pages.products.homeEquityLoan}
                element={<HomeEquityLoan />}
              />
            )}
            {/* Resource center */}
            <Route path={pages.resourceCenter} element={<ResourceCenter />} />
            <Route
              path={`${pages.resourceCenter}/:slug`}
              element={<ResourceCenterArticle />}
            />

            {/* Resource center redirects */}
            <Route
              path={pages.resourceCenterOldRoute}
              element={<Navigate to={pages.resourceCenter} replace />}
            />
            <Route
              path={`${pages.resourceCenterOldRoute}/:slug`}
              element={<Redirect to={`${pages.resourceCenter}/:slug`} />}
            />

            {/* NPS Landing Page */}
            <Route path={pages.submitNps} element={<NpsPage />} />

            {/* ADA Compliance Landing Page */}
            <Route
              path={pages.adaAccessibility}
              element={<AdaAccessibilityPage />}
            />

            {/* Generic error page */}
            <Route path={pages.error} element={<ErrorPage />} />

            {/* Default to 404 page */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
