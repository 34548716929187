import { RefObject, useEffect } from "react";
import { useSelector } from "react-redux";
import { Statsig } from "statsig-react";
import {
  getSessionKey,
  getUserId,
  logExperimentExposure,
  NewExperimentNameGroup,
  NoneExperimentGroup,
} from "../helpers/experiment-exposure";
import { RootState } from "../stores";
import { User } from "../types";
import { EXPERIMENTS, ExperimentStatus } from "./use-ab-test";
import useOnScreen from "./use-on-screen";

type ExperimentNameValue = (typeof EXPERIMENTS)[keyof typeof EXPERIMENTS];
interface UseExperimentManualExposureOnScreenProps {
  experimentName: ExperimentNameValue;
  experimentStatus: ExperimentStatus;
  selectedGroupsToMakeExposure: (
    | NewExperimentNameGroup
    | NoneExperimentGroup
  )[];
  ref: RefObject<HTMLElement>;
}

const useExperimentManualExposureOnScreen = ({
  experimentName,
  experimentStatus,
  selectedGroupsToMakeExposure,
  ref,
}: UseExperimentManualExposureOnScreenProps) => {
  const { user: userData } = useSelector((state: RootState) => state.user);
  const isOnScreen = useOnScreen(ref);
  const { id, isAuth } = getUserId(userData as User);
  const sessionKey = getSessionKey(id, isAuth, experimentName);

  useEffect(() => {
    if (
      isOnScreen &&
      selectedGroupsToMakeExposure?.includes(experimentStatus.groupName)
    ) {
      try {
        const config =
          Statsig.getExperimentWithExposureLoggingDisabled(experimentName);
        logExperimentExposure(experimentName, sessionKey, config);
      } catch (err) {
        console.error(err);
      }
    }
  }, [
    isOnScreen,
    experimentName,
    sessionKey,
    experimentStatus.groupName,
    selectedGroupsToMakeExposure,
  ]);
};

export default useExperimentManualExposureOnScreen;
