import { translate as t } from "../helpers/i18n";

const Loader = () => (
  <div
    className="app-component splash d-flex justify-content-center h-100 pt-6 bg-primary-01 text-bg-primary overflow-hidden fade-in"
    data-component="App"
  >
    <div className="spinner-border mt-6" role="status">
      <span className="visually-hidden">{t("accessibility.loading")}</span>
    </div>
  </div>
);

export default Loader;
