import BaseModal from "../modal";
import Button from "../../atoms/button";
import { translate as t } from "../../../helpers/i18n";
import { Trans } from "react-i18next";
import { LogoutLink } from "../../../modules/layout/header/header-auth";
import { SubservicerLink } from "../../../components/subservicer-link";

export interface DeclinedTCConfirmationModalProps {
  show: boolean;
  onGoBack?: () => void;
  isUncompletedProfile?: boolean;
  onExitProfileSetup?: () => void;
}

const DeclinedTCConfirmationModal = ({
  show,
  onGoBack,
  isUncompletedProfile,
  onExitProfileSetup,
}: DeclinedTCConfirmationModalProps) => {
  const title = isUncompletedProfile
    ? t("declined_terms_conditions_modal_sso.title")
    : t("declined_terms_conditions_modal.title");

  const backButtonText = isUncompletedProfile
    ? t("declined_terms_conditions_modal_sso.back_button")
    : t("declined_terms_conditions_modal.back_button");

  const body = isUncompletedProfile
    ? t("declined_terms_conditions_modal_sso.body")
    : t("declined_terms_conditions_modal.body");

  return (
    <BaseModal
      show={show}
      title={title}
      className="d-flex justify-content-center"
      headerClassName="text-subtitle-1"
      dialogClassName="terms-decline-conformation-modal"
      buttons={
        <div className="d-flex flex-column flex-md-row w-100 gap-3">
          <Button className="w-100 flex-1" type="button" onClick={onGoBack}>
            {backButtonText}
          </Button>
          {isUncompletedProfile ? (
            <Button
              className="w-100 flex-1"
              color="primary"
              onClick={onExitProfileSetup}
            >
              {t("declined_terms_conditions_modal_sso.exit_button")}
            </Button>
          ) : (
            <LogoutLink className="w-100 flex-1" buttonVariant="outline" />
          )}
        </div>
      }
    >
      <Trans
        i18nKey={body}
        components={{
          bold: <strong />,
          subservicer: (
            <SubservicerLink
              translationKey="declined_terms_conditions_modal.subservicer_name"
              module="accept-terms-conditions"
              isConfirmationModal={false}
            />
          ),
        }}
      />
    </BaseModal>
  );
};

export default DeclinedTCConfirmationModal;
