import { cx } from "class-variance-authority";
import { t } from "i18next";
import { ReactNode } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";

type Steps = "introduction" | "password_setup";

export interface BannerContainerProps {
  show: boolean;
  showSteps: boolean;
  boxContent: ReactNode;
  extraContent?: ReactNode;
  step: Steps;
}

const TitleSection = ({ showSteps, step }: Partial<BannerContainerProps>) => (
  <>
    {showSteps && (
      <p className="text-eyebrow mb-4">{t(`sso_banner.${step}.step`)}</p>
    )}
    <h1 className="text-headline-04 text-md-headline-03 mb-0 ">
      <Trans i18nKey={`sso_banner.${step}.title_cobranded`} />
    </h1>
  </>
);

const BannerContainer = ({
  show,
  showSteps,
  boxContent,
  step,
  extraContent,
}: BannerContainerProps) =>
  show && (
    <div
      className="sso-banner bg-gradient text-white"
      data-testid="sso-banner-container"
    >
      <div className="container-md banner-container py-40px py-xl-7 px-xl-0 ">
        <div className="d-flex flex-column d-none d-md-block d-lg-none mb-5">
          <TitleSection showSteps={showSteps} step={step} />
        </div>
        <div className="d-flex justify-content-between flex-md-row flex-column gap-4 gap-md-6 gap-100px">
          <div
            className={cx(
              "d-flex flex-column gap-4 align-self-start align-self-md-center align-self-lg-start",
              !showSteps && "pt-xl-7"
            )}
          >
            <div className="d-block d-md-none d-lg-block">
              <TitleSection showSteps={showSteps} step={step} />
            </div>
            <p className="m-0">
              <Trans
                i18nKey={`sso_banner.${step}.description_cobranded`}
                components={{
                  linkTag: (
                    <Link className="text-white fw-bold" to={pages.index} />
                  ),
                }}
              />
            </p>
            {extraContent ? (
              <div className="d-none d-md-block pt-xl-3">{extraContent}</div>
            ) : null}
          </div>
          <div className="password-input-container align-self-center border border-1 rounded-3 border-primary-05 p-4  col-12 col-md-6 col-xl-5">
            {boxContent}
          </div>
        </div>
      </div>
      {extraContent ? (
        <div className="d-md-none bg-primary-07 position-fixed fixed-bottom">
          <div className="container-xl mt-3 mb-4">
            <div className="d-flex flex-column flex-md-row w-100 justify-content-center">
              {extraContent}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );

export default BannerContainer;
