import { ChangeEvent, FormEvent } from "react";
import { translate as t } from "../../../helpers/i18n";
import TermAndConditionsDashboardComponent from "../terms-and-conditions-dashboard-component";
import BaseModal from "../modal";
import { Button } from "../../atoms/button";
import { TERMS_CONDITIONS_LAST_UPDATED } from "../../../helpers/constants";
import CheckboxGroup, { TermsData } from "./checkbox-group";

interface AcceptTCModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  isFailure: boolean;
  isUpdatedTermsAndConditions?: boolean;
  termsData: TermsData;
  handleChange: (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: boolean
  ) => void;
}

const AcceptTCModal = ({
  show,
  onClose,
  onSubmit,
  isLoading,
  isFailure,
  isUpdatedTermsAndConditions,
  handleChange,
  termsData,
}: AcceptTCModalProps) => (
  <BaseModal
    show={show}
    onClose={onClose}
    onCloseButtonClick={onClose}
    fullscreen="md-down"
    dialogClassName="terms-modal-dialog"
    contentClassName="pb-3"
    bodyClassName="flex-grow-0 py-5 px-4 pt-md-0 pb-md-4 px-md-40px px-xl-7 overflow-visible bg-white"
    footerClassName="px-4 pt-4 pb-3 px-md-40px px-xl-7 bg-white"
    headerClassName="d-none d-md-flex pb-1"
    backdropClassName="d-none d-md-block"
    buttons={
      <form onSubmit={onSubmit} noValidate className="w-100 z-2">
        <div className="d-grid gap-3 mb-3">
          <CheckboxGroup termsData={termsData} handleChange={handleChange} />

          {isFailure && (
            <div className="d-block invalid-feedback">
              {t("error.regular_message")}
            </div>
          )}
        </div>
        <div className="d-flex flex-column w-100 flex-md-row-reverse gap-3">
          <Button
            data-testid="terms-conditions-agree"
            isLoading={isLoading}
            className="btn btn-primary"
            type="submit"
          >
            {t("terms_conditions.continue_button")}
          </Button>
          <Button variant="link" onClick={onClose} type="button">
            {t("terms_conditions.cancel_button")}
          </Button>
        </div>
      </form>
    }
  >
    <div className="text-subtitle-01 text-headline-05 text-md-headline-04 text-typography-surface-high-emphasis mb-3">
      {isUpdatedTermsAndConditions
        ? t("updated_terms_conditions.modal_title")
        : t("terms_conditions.modal_title")}
    </div>
    <div className="mb-3">
      <p className="text-body-02">{TERMS_CONDITIONS_LAST_UPDATED}</p>
    </div>
    <div className="terms-accordion z-1">
      <TermAndConditionsDashboardComponent isContainedInModal />
    </div>
  </BaseModal>
);

export default AcceptTCModal;
