const ConsentToReceiveTelephoneComms = () => (
  <>
    <p className="mb-4">
      <span className="fw-bold">Consent to Communications.</span> You agree to
      receive telephone calls and SMS/text messages from Lakeview Loan
      Servicing, LLC, Lakeview Household Insurance Solutions, LLC, Fastlane
      Financial Solutions, LLC, Fastlane Technology Solutions, LLC, and their
      respective affiliates, subsidiaries, and service providers (collectively,
      “we,” “us,” and “our”) at any phone number you provide us. We may leave
      voice or SMS/Text messages for any purpose permitted by applicable law,
      including for purposes of collections. You agree that this consent permits
      us to contact you in any way, including through the use of automated
      dialers and allows us to leave pre-recorded voice messages, artificial
      voice messages, and automated SMS/text messages at the number or any voice
      mailbox that is associated with a phone number you have provided us.
      Automated messages may be played when the telephone is answered whether by
      you or someone else. In the event that an agent or representative calls,
      you agree that he or she may also leave a message on your answering
      machine, voice mail, or send one via text. You certify, warrant, and
      represent that you are permitted to receive calls at each of the telephone
      numbers you have provided to us. You agree to promptly alert us whenever
      you stop using a particular telephone number.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Prior Authorization and Discontinuance.</span>{" "}
      You further authorize us to call or send SMS/text messages to your
      telephone number even if you have previously elected to place such number
      on a state or federal do-not-call list or have previously asked to be
      placed on an internal opt-out list.  Any and all instructions you may have
      previously provided to us about calls or SMS/text messages to your
      telephone number are invalid and you agree can be disregarded.  We reserve
      the right to modify or discontinue, temporarily or permanently, all or any
      part of these calls or SMS/text messages, with or without notice to you.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Marketing Communications.</span> By agreeing to
      this consent, you authorize us, our agents, and our service providers to
      deliver or cause to be delivered advertisements and telemarketing calls
      and SMS/text messages to the phone number you have provided to us using an
      automatic telephone dialing system or an artificial or prerecorded voice.
      You agree that these calls and messages may concern the Services (as
      described in the Terms of Use) or any products that we may provide, and
      that such calls and messages may be for marketing purposes, including for
      products and services we think might be of interest to you. Your consent
      to receive marketing telephone calls and text messages is not a condition
      for receiving services or products from us. You may opt out of marketing
      communications by emailing fastlanesupport@lakeview.com.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Additional Charges and Liability.</span> You
      agree that you are solely responsible for any charges from your telephone
      service or data provider as a result from our telephone calls and text
      messages to you. Text frequency may vary and may be recurring. We and any
      carrier are not liable for delayed or undelivered messages.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Disputes.</span> You agree that any claim,
      controversy, or dispute arising out of or related to this consent will be
      subject to the Arbitration Agreement in our Terms of Use unless you opted
      out of the Arbitration Agreement.
    </p>
  </>
);

export default ConsentToReceiveTelephoneComms;
