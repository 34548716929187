import { useDispatch } from "react-redux";
import { AppDispatch } from "../../stores";
import { showToast } from "../../stores/toast-slice";
import { translate as t } from "../../helpers/i18n";

const ErrorFallback = ({ error }: { error: Error }) => {
  const dispatch = useDispatch<AppDispatch>();

  dispatch(
    showToast({
      status: "error",
      content: error.message || t("error.regular_message"),
    })
  );

  return (
    <div>
      <p>{t("error.regular_message")}</p>
      <pre>{error.message}</pre>
    </div>
  );
};

export default ErrorFallback;
