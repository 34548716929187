import {
  MIXPANEL_EVENTS,
  getLinkedSessionIds,
  getNewSessionFlag,
  getOrCreateSessionId,
  LINKED_SESSION_IDS,
  removeNewSessionFlag,
  SESSION_ID,
  setLinkedSessionId,
  setSessionId,
} from "analytics";
import { useLayoutEffect } from "react";
import mixpanel from "mixpanel-browser";
import { datadogRum } from "@datadog/browser-rum";
import { useSearchParams } from "react-router-dom";

/**
 * Sets the session id and registers on the different services
 */
export const useSession = () => {
  //if there is no user logged in, will register the session anyway
  const [searchParams] = useSearchParams();
  const utmMedium = searchParams.get("utm_medium");
  const utmContent = searchParams.get("utm_content");

  useLayoutEffect(() => {
    const sessionId = getOrCreateSessionId();

    if (utmMedium === "session_id" && utmContent) {
      setLinkedSessionId(utmContent);
    }

    registerSession(sessionId);
  }, [utmContent, utmMedium]);
};

/**
 * Helper to register the session id on the different services
 *
 * @param sessionId {string} - Session id to register
 */
export const registerSession = (sessionId: string) => {
  // These session IDs come from other apps
  const linkedSessionIds = getLinkedSessionIds();

  // Register session with Mixpanel
  mixpanel.register({
    [SESSION_ID]: sessionId,
    [LINKED_SESSION_IDS]: linkedSessionIds,
  });

  if (getNewSessionFlag()) {
    mixpanel.track(MIXPANEL_EVENTS.SESSION_START);
    removeNewSessionFlag();
  }

  // Register session with DatadogRum
  datadogRum.setGlobalContextProperty("enduser.session_id", sessionId);

  // save it in localStorage
  setSessionId(sessionId);
};

/**
 * Ends the session and removes any user related data from the services
 */
export const unregisterSession = () => {
  // Deletes the flags from experiment exposures
  Object.keys(sessionStorage).forEach((key) => {
    if (key.includes("_exp_")) {
      sessionStorage.removeItem(key);
    }
  });

  // Reset the user identity on logout
  mixpanel.reset();

  // unset the user id in DatadogRum
  datadogRum.setGlobalContextProperty("enduser.id", "");

  // End the session
  mixpanel.track(MIXPANEL_EVENTS.SESSION_END);
};
