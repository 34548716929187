import { ReactComponent as InterfaceContentChartSVG } from "purple-rain/icons/interface-content-chart.svg";
import { ReactComponent as MoneyCashSearchDollarSVG } from "purple-rain/icons/money-cash-search-dollar.svg";
import { ReactComponent as MoneyGraphBarIncreaseSVG } from "purple-rain/icons/money-graph-bar-increase.svg";
import { translate as t } from "../../../helpers/i18n";

const BENEFITS_LIST = [
  {
    image: (
      <InterfaceContentChartSVG className="text-white" width={24} height={24} />
    ),
    translationKey: "sso_banner.benefits_box.better_tracking",
  },
  {
    image: (
      <MoneyGraphBarIncreaseSVG className="text-white" width={24} height={24} />
    ),
    translationKey: "sso_banner.benefits_box.enhanced_awareness",
  },
  {
    image: (
      <MoneyCashSearchDollarSVG className="text-white" width={24} height={24} />
    ),
    translationKey: "sso_banner.benefits_box.customized_suggestions",
  },
];

const BenefitsBox = () => {
  return (
    <>
      <div className="d-flex flex-column gap-4">
        {BENEFITS_LIST.map((benefit) => (
          <div className="d-flex gap-3" key={benefit.translationKey}>
            {benefit.image}
            <div className="d-flex flex-column gap-1">
              <p className="text-uppercase m-0 fw-semibold box-title">
                {t(`${benefit.translationKey}.title`)}
              </p>
              <p className="m-0 text-body-01 fw-light">
                {t(`${benefit.translationKey}.description`)}
              </p>
            </div>
          </div>
        ))}
      </div>
      <p className="m-0 text-body-02 fst-italic mt-4">
        {t("sso_banner.benefits_box.availability_disclaimer")}
      </p>
    </>
  );
};

export default BenefitsBox;
