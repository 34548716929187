import { cva, cx, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";

const buttonStyles = cva("btn align-items-center gap-2", {
  variants: {
    variant: {
      solid: "",
      ghost: "",
      outline: "",
      link: "btn-link",
    },
    color: {
      primary: "btn-primary",
      secondary: "btn-secondary",
      default: "",
    },
    size: {
      lg: "btn-lg",
      md: "btn-md",
      sm: "btn-sm",
    },
    isLoading: {
      true: "btn-is-loading",
      false: "d-flex",
    },
    fullWidth: {
      true: "w-100",
      false: "",
    },
  },
  compoundVariants: [
    {
      variant: "solid",
      color: "primary",
      class: "text-white",
    },
    {
      variant: "ghost",
      class: "btn-ghost",
    },
    {
      variant: "ghost",
      color: "primary",
      class: "btn-ghost-primary",
    },
    {
      variant: "outline",
      color: "primary",
      class: "btn-outline-primary",
    },
    {
      variant: "solid",
      color: "secondary",
      class: "btn-secondary text-white",
    },
    {
      variant: "ghost",
      color: "secondary",
      class: "btn-ghost-secondary",
    },
    {
      variant: "outline",
      color: "secondary",
      class: "btn-outline-secondary",
    },
  ],
  defaultVariants: {
    variant: "solid",
    size: "md",
    color: "default",
    isLoading: false,
    fullWidth: false,
  },
});

type ButtonStyleProps = VariantProps<typeof buttonStyles>;

export interface ButtonProps
  extends ButtonStyleProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  children: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
}

const Button = ({
  variant = "solid",
  size = "md",
  children,
  icon,
  className = "",
  isLoading = false,
  disabled = false,
  fullWidth = false,
  color,
  ...rest
}: ButtonProps) => {
  const spinnerClass = `spinner spinner-${size} spinner-${variant}`;
  const isButtonDisabled = disabled || isLoading;

  return (
    <button
      className={cx(
        buttonStyles({ variant, color, size, isLoading, fullWidth }),
        `justify-content-center d-flex gap-2 gap-md-12px text-nowrap ${className}`
      )}
      disabled={isButtonDisabled}
      {...rest}
    >
      {isLoading && <span role="status" className={spinnerClass} />}
      {icon}
      {children}
    </button>
  );
};

export { Button, Button as default };
