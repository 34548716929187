import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { handleValidation } from "../../../helpers/form-validation";
import {
  AFBA_VERSION,
  TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME,
  TERMS_CONDITIONS_VERSION,
} from "../../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../stores";
import { useSearchParams } from "react-router-dom";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { createSSOUser } from "../../../stores/sso-user-slice";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import AccountCreatedModal from "./account-created-modal";
import AcceptTCModal from "./accept-tc-modal";
import DeclinedTCConfirmationModal from "./declined-tc-confirmation-modal";
import AcceptAfBAModal from "./accept-afba-modal";
import { User } from "../../../types";
import { updateUser } from "../../../stores/user-slice";

interface Props {
  module?: string;
  accountCreatedModule?: string;
  password?: string;
  isUpdatedTermsAndConditions?: boolean;
  isUncompletedProfile?: boolean;
  showOnlyAfBA?: boolean;
  closeOnlyAfBa?: () => void;
  acceptOnlyAfBa?: () => void;
  onCloseTermsModal?: () => void;
  onCloseAccountCreation?: () => void;
  onSubmitAccountCreation?: () => void;
}

const TermsAndConditionsModal = ({
  module = "sso-terms-conditions",
  accountCreatedModule = "sso-account-creation-success",
  password,
  isUpdatedTermsAndConditions,
  isUncompletedProfile,
  showOnlyAfBA = false,
  closeOnlyAfBa = () => {},
  acceptOnlyAfBa = () => {},
  onCloseTermsModal = () => {},
  onCloseAccountCreation = () => {},
  onSubmitAccountCreation = () => {},
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const {
    user: userState,
    ssoUser: ssoUserState,
    tavant: { isTavantProcess },
  } = useSelector((state: RootState) => state);
  const { dismissAfBAModal } = useSSOValues();

  const [showAcceptTCModal, setShowAcceptTCModal] = useState<boolean>(
    !!isUpdatedTermsAndConditions
  );
  const [showDeclinedTCConfirmationModal, setShowDeclinedTCConfirmationModal] =
    useState<boolean>(false);
  const [showAcceptAfBAModal, setShowAcceptAfBAModal] =
    useState<boolean>(false);
  const [termsData, setTermsData] = useState({
    agreement_terms: false,
    credit_reports: false,
    electronic_signatures: false,
    marketing_telephone_communication: false,
  });

  const handleLakeviewTerms = (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: boolean
  ) => {
    setTermsData((prev) => ({
      ...prev,
      [e.target.name]: !currentValue,
    }));
  };

  const { isPendingFastlaneAccount } = useSSOValues();

  const { user, updateState } = userState;
  const ssoUserStatus = ssoUserState.status;
  const isUserAfBAVersionUpToDate = user?.afba_version === AFBA_VERSION;

  const isIdling = ssoUserStatus === "idle" || updateState === "idle";
  const hasFailed = ssoUserStatus === "failed" || updateState === "failed";
  const isLoading = ssoUserStatus === "loading" || updateState === "loading";
  const isModalOpen =
    searchParams.get(TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME) === "true";

  const showTermsModal =
    (isPendingFastlaneAccount && isModalOpen && !!password) ||
    showAcceptTCModal;

  const showCreatedAccountModal = ssoUserState.showCreatedAccountModal;

  const handleCloseTermsModal = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  // after the update or create request is fulfilled we remove the query param
  useEffect(() => {
    if (ssoUserState.showTCModal === false) {
      handleCloseTermsModal();
    }
  }, [ssoUserState.showTCModal, handleCloseTermsModal]);

  // if account is created we update the user and close the modal
  useEffect(() => {
    if (showCreatedAccountModal) {
      handleCloseTermsModal();
    }
  }, [showCreatedAccountModal, handleCloseTermsModal]);

  useEffect(() => {
    if (showTermsModal) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: module,
      });
    }
  }, [showTermsModal, module]);

  useEffect(() => {
    setShowAcceptAfBAModal(!!showOnlyAfBA);
  }, [showOnlyAfBA]);

  const handleDismissModal = () => {
    setShowAcceptTCModal(false);
    handleCloseTermsModal();
    setShowDeclinedTCConfirmationModal(true);
  };

  const handleCreateSSOUser = () => {
    const data = {
      password,
      terms_version: TERMS_CONDITIONS_VERSION,
      marketing_telephone_communication:
        termsData.marketing_telephone_communication,
      marketing_communication: true,
      use_electronic_signatures_version: TERMS_CONDITIONS_VERSION,
      obtain_credit_reports_version: TERMS_CONDITIONS_VERSION,
    };

    mixpanel.track(MIXPANEL_EVENTS.FORM_SUBMIT, {
      module: module,
      fields: {
        terms_version: TERMS_CONDITIONS_VERSION,
        marketing_telephone_communication:
          termsData.marketing_telephone_communication,
        use_electronic_signatures_version: TERMS_CONDITIONS_VERSION,
        obtain_credit_reports_version: TERMS_CONDITIONS_VERSION,
      },
    });

    dispatch(
      createSSOUser({
        ...data,
      })
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const isFormValid = handleValidation(e);

    if (!isFormValid) {
      return;
    }

    setShowAcceptTCModal(false);
    createOrUpdateUser();
    onSubmitAccountCreation?.();
  };

  // Update the user's terms and conditions version
  const createOrUpdateUser = () => {
    // Only proceed if the button hasn't been clicked before (isIdling) or if the previous attempt failed (hasFailed)
    // This prevents unnecessary API calls and ensures we only create/update when needed
    if (!isIdling && !hasFailed) {
      return;
    }

    if (isUncompletedProfile) {
      handleCreateSSOUser();
      return;
    }

    const data: Partial<User> = {
      terms_version: TERMS_CONDITIONS_VERSION,
      marketing_telephone_communication:
        termsData.marketing_telephone_communication,
      marketing_communication: true,
      use_electronic_signatures_version: TERMS_CONDITIONS_VERSION,
      obtain_credit_reports_version: TERMS_CONDITIONS_VERSION,
    };
    dispatch(updateUser(data));
  };

  // Go back confirmation button logic
  const handleGoBackTCConfirmation = () => {
    setShowDeclinedTCConfirmationModal(false);
    setShowAcceptTCModal(true);
  };

  const handleExitProfileSetup = () => {
    setShowDeclinedTCConfirmationModal(false);
    handleCloseTermsModal();
    onCloseTermsModal?.();

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: module,
      button: "close",
    });
  };

  const handleCloseAfBA = () => {
    dismissAfBAModal();
    setShowAcceptAfBAModal(false);
    if (showOnlyAfBA) {
      closeOnlyAfBa && closeOnlyAfBa();
    }
  };

  const handleAcceptAfBA = () => {
    const data: Partial<User> = {
      afba_version: AFBA_VERSION,
    };

    dispatch(updateUser(data));
    setShowAcceptAfBAModal(false);
    acceptOnlyAfBa?.();
  };

  const handleShowAfBAModal = () => {
    if (!isUserAfBAVersionUpToDate && !isTavantProcess) {
      setShowAcceptAfBAModal(true);
    }

    onCloseAccountCreation?.();
  };

  return (
    <>
      <AccountCreatedModal
        show={showCreatedAccountModal}
        module={accountCreatedModule}
        onClose={handleShowAfBAModal}
      />
      {!showOnlyAfBA && (
        <AcceptTCModal
          show={showTermsModal}
          onClose={handleDismissModal}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          isFailure={hasFailed}
          termsData={termsData}
          handleChange={handleLakeviewTerms}
          isUpdatedTermsAndConditions={isUpdatedTermsAndConditions}
        />
      )}
      <AcceptAfBAModal
        show={showAcceptAfBAModal}
        onCancel={handleCloseAfBA}
        onAccept={handleAcceptAfBA}
        isLoading={isLoading}
      />
      <DeclinedTCConfirmationModal
        show={showDeclinedTCConfirmationModal}
        onGoBack={handleGoBackTCConfirmation}
        isUncompletedProfile={isUncompletedProfile}
        onExitProfileSetup={handleExitProfileSetup}
      />
    </>
  );
};

export default TermsAndConditionsModal;
