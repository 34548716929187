import { ReactNode } from "react";
import { pages } from "../../../helpers/pages";
import { ExternalLink } from "../../../components/external-link";

/**
 * Disclosures types used in the footer section
 */
export const DISCLOSURES_TYPES = {
  ESTIMATED_VALUE: "ESTIMATED_VALUE",
  PERSONAL_GOAL_INTEREST_RATE: "PERSONAL_GOAL_INTEREST_RATE",
  PERSONAL_GOAL_LEGAL_DISCLAIMER: "PERSONAL_GOAL_LEGAL_DISCLAIMER",
  OFFER_CASH_OUT_HELOAN: "OFFER_CASH_OUT_HELOAN",
  OFFER_CASH_OUT_ELIGIBILITY: "OFFER_CASH_OUT_ELIGIBILITY",
  OFFER_DEBT_PAYMENT_BASED: "OFFER_DEBT_PAYMENT_BASED",
  APR_CALCULATIONS: "APR_CALCULATIONS",
  ASSUMABLE_INTEREST_RATES: "ASSUMABLE_INTEREST_RATES",
  ASSUMABLE_REWARDS_PROGRAM: "ASSUMABLE_REWARDS_PROGRAM",
  OFFER_CASH_OUT_ONLY: "OFFER_CASH_OUT_ONLY",
  LHIS_AD: "LHIS_AD",
} as const;

export type DisclosureTypeKey = keyof typeof DISCLOSURES_TYPES;

export interface DisclosureContent {
  symbol: string;
  translationKey: string;
  activePath: string;
  href: string;
  components?: {
    [key: string]: ReactNode;
  };
}

export type DisclosuresType = Record<DisclosureTypeKey, DisclosureContent>;

export const DISCLOSURES: DisclosuresType = {
  ESTIMATED_VALUE: {
    symbol: "*",
    translationKey: "layout.footer.disclosures.estimated_values",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.ESTIMATED_VALUE,
  },
  PERSONAL_GOAL_INTEREST_RATE: {
    symbol: "‡",
    translationKey: "layout.footer.disclosures.personal_goals_interest_rate",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_INTEREST_RATE,
  },
  PERSONAL_GOAL_LEGAL_DISCLAIMER: {
    symbol: "1",
    translationKey: "layout.footer.disclosures.personal_goals_legal_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_LEGAL_DISCLAIMER,
  },
  OFFER_CASH_OUT_HELOAN: {
    symbol: "2",
    translationKey: "layout.footer.disclosures.offer_cash_out_heloan",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_HELOAN,
  },
  OFFER_CASH_OUT_ELIGIBILITY: {
    symbol: "3",
    translationKey: "layout.footer.disclosures.offer_debt.item_03",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_ELIGIBILITY,
  },
  OFFER_DEBT_PAYMENT_BASED: {
    symbol: "4",
    translationKey: "layout.footer.disclosures.offer_debt.item_04",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_DEBT_PAYMENT_BASED,
  },
  APR_CALCULATIONS: {
    symbol: "5",
    translationKey: "layout.footer.disclosures.offer_debt.item_05",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.APR_CALCULATIONS,
  },
  ASSUMABLE_INTEREST_RATES: {
    symbol: "6",
    translationKey:
      "layout.footer.disclosures.offer_assumable_mortgage.item_06",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.ASSUMABLE_INTEREST_RATES,
  },
  ASSUMABLE_REWARDS_PROGRAM: {
    symbol: "7",
    translationKey:
      "layout.footer.disclosures.offer_assumable_mortgage.item_07",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.ASSUMABLE_REWARDS_PROGRAM,
  },
  OFFER_CASH_OUT_ONLY: {
    symbol: "8",
    translationKey: "layout.footer.disclosures.offer_cash_out_only",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_ONLY,
  },
  LHIS_AD: {
    symbol: "9",
    translationKey: "layout.footer.disclosures.lhis.item_09",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.LHIS_AD,
    components: {
      link1: (
        <ExternalLink
          href="https://www.lakeviewinsurancesolutions.com/carriers/"
          targetBlank
        />
      ),
      link2: (
        <ExternalLink
          href="https://www.lakeviewinsurancesolutions.com/agency-licenses/"
          targetBlank
        />
      ),
      link3: (
        <ExternalLink
          href="https://www.lakeviewinsurancesolutions.com/"
          targetBlank
        />
      ),
    },
  },
} as const;
