import { useSelector } from "react-redux";
import {
  DynamicConfig,
  useExperimentWithExposureLoggingDisabled,
} from "statsig-react";
import {
  ExperimentNameValue,
  mapExperimentNameGroup,
  NewExperimentNameGroup,
  NoneExperimentGroup,
} from "../helpers/experiment-exposure";
import { isUnmarketableState } from "../helpers/utils";
import { RootState } from "../stores";
import { FEATURE_GATES, useFeatureGate } from "./use-feature-gate";
import { useMortgageValues } from "./use-mortgage-values";

export interface ExperimentStatus {
  isExperimentAvailable: boolean;
  groupName: NewExperimentNameGroup | NoneExperimentGroup;
}
/**
 * List of A/B tests present in the project
 * Includes the parameters used in the experiments for ease of access
 */
export const EXPERIMENTS = {
  TAVANT_SSO: "tavant_sso_ab",
  CASH_OUT_FLOW_INGRESS: "cash_out_pdp_ingress_ab",
  DIGITAL_INSURANCE_AD: "digital_insurance_ab",
  TAVANT_DIGITAL_HELOAN: "tavant_digital_heloan",
} as const;

export type ExperimentConfig = Partial<{
  [key in keyof typeof EXPERIMENTS]: boolean;
}>;

/**
 * configuration for each experiment including the excluded groups
 */
const EXPERIMENT_EXCLUDED_GROUPS: Record<
  (typeof EXPERIMENTS)[keyof typeof EXPERIMENTS],
  (NewExperimentNameGroup | NoneExperimentGroup)[]
> = {
  [EXPERIMENTS.TAVANT_SSO]: ["None", "Control1"],
  [EXPERIMENTS.CASH_OUT_FLOW_INGRESS]: ["None", "Control1"],
  [EXPERIMENTS.DIGITAL_INSURANCE_AD]: ["None", "Control1"],
  [EXPERIMENTS.TAVANT_DIGITAL_HELOAN]: ["None", "Control1"],
};

/**
 * configuration for each experiment including the excluded groups post launch
 */
const EXPERIMENT_EXCLUDED_GROUPS_POST_LAUNCH: Record<
  (typeof EXPERIMENTS)[keyof typeof EXPERIMENTS],
  (NewExperimentNameGroup | NoneExperimentGroup)[]
> = {
  [EXPERIMENTS.TAVANT_SSO]: ["Control1"],
  [EXPERIMENTS.CASH_OUT_FLOW_INGRESS]: ["Control1"],
  [EXPERIMENTS.DIGITAL_INSURANCE_AD]: ["Control1"],
  [EXPERIMENTS.TAVANT_DIGITAL_HELOAN]: ["Control1"],
};

/** Returns whether the configuration corresponds to a user in the experiment
 - If the groupName value exists and the experiment is not active, it means the experiment was shipped/launched.
 - If the groupName value exists and the experiment is active, it means exactly that, the experiment is active but not shipped/launched.
 - If the groupName value is null and the experiment is not active, it means the experiment hasn't started yet.
*/
const configCheck = (
  config: DynamicConfig,
  experimentName: ExperimentNameValue,
  doesMeetExperimentCriteria = true,
  doesMeetExperimentCriteriaPostLaunch = false
): ExperimentStatus => {
  const excludedGroups = EXPERIMENT_EXCLUDED_GROUPS[experimentName];
  const excludedGroupsPostLaunch =
    EXPERIMENT_EXCLUDED_GROUPS_POST_LAUNCH[experimentName];
  const isExperimentActive = config.getIsExperimentActive();

  const groupName = config.getGroupName();
  const mappedGroupName = mapExperimentNameGroup(
    groupName || ("None" as NoneExperimentGroup)
  );

  /**
   *  When an experiment is shipped/launched, it becomes inactive, and the selected groupName
   *  value at the time of the experiment's decision-making is returned
   *
   *  Note: when an experiment is created but not initialize, the value of the groupName
   *  is `null` and the experiment is not active
   * */
  const isExperimentLaunched = !!groupName && !isExperimentActive;

  // If the experiment is launched and meets the post-launch criteria, return true
  if (
    isExperimentLaunched &&
    doesMeetExperimentCriteriaPostLaunch &&
    !excludedGroupsPostLaunch.includes(mappedGroupName)
  ) {
    return {
      isExperimentAvailable: true,
      groupName: mappedGroupName,
    };
  }

  // If the user does not meet pre-launch criteria, return false
  if (!doesMeetExperimentCriteria)
    return {
      isExperimentAvailable: false,
      groupName: "None",
    };

  // Check if the experiment is launched and the group is not excluded, return true
  if (isExperimentLaunched && mappedGroupName !== "None")
    return {
      isExperimentAvailable: !excludedGroups.includes(mappedGroupName),
      groupName: mappedGroupName,
    };

  // otherwise, check if the group is not excluded and the experiment is active
  return {
    // Check if the group is not excluded & the experiment is active
    isExperimentAvailable:
      (!excludedGroups.includes(mappedGroupName) && isExperimentActive) ||
      false,
    groupName: mappedGroupName,
  };
};

// Checks if the user meets the criteria to be included in the experiment pre-launch
export const useExperimentsCriteriaPreLaunch = (experimentName: string) => {
  const { initialized: isUserInitialized, user: userData } = useSelector(
    (state: RootState) => state.user
  );
  const {
    property: { property },
  } = useSelector((state: RootState) => state);
  const { isLoading: isLoadingMortgage, isNonSolicit } = useMortgageValues();
  const state = property?.address?.state;
  const isInsuranceAdActive =
    !isLoadingMortgage && !isNonSolicit && !isUnmarketableState(state);
  const isUserAuthenticated = isUserInitialized && !!userData;
  const isSSOUserWithoutFastlaneAccount =
    userData?.did_sso && !userData?.has_fastlane_account;
  const isTavantDigitalHeloanActive =
    isUserAuthenticated && !isSSOUserWithoutFastlaneAccount;

  const isCashOutEnabled = useFeatureGate(
    FEATURE_GATES.CASH_OUT_REFINANCE_LOAN_QUOTE
  );

  const isBoltInsuranceEnabled = useFeatureGate(
    FEATURE_GATES.BOLT_INSURANCE_AD
  );

  const isTavantDigitalHeloanEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_TAVANT_DIGITAL_HELOAN
  );

  const experimentsCriteria = {
    [EXPERIMENTS.TAVANT_SSO]: isUserAuthenticated,
    [EXPERIMENTS.CASH_OUT_FLOW_INGRESS]: isCashOutEnabled,
    [EXPERIMENTS.DIGITAL_INSURANCE_AD]:
      isBoltInsuranceEnabled && isInsuranceAdActive,
    /**
     * based on CLF-3532:
     * "6. The experience of unauthorized sessions (include subservicer SSO users who have
     * not set a password) should match the Control, even though they are not in the experiment.""
     */
    [EXPERIMENTS.TAVANT_DIGITAL_HELOAN]:
      isTavantDigitalHeloanEnabled && isTavantDigitalHeloanActive,
  };

  return experimentsCriteria[experimentName] ?? false;
};

// Checks if the user meets the criteria to be included in the experiment post-launch
const useExperimentsCriteriaPostLaunch = (experimentName: string) => {
  const experimentCriteriaPostLaunch = {
    // All user will see the experiment if the Test group is selected on launch
    [EXPERIMENTS.TAVANT_DIGITAL_HELOAN]: true,
  };

  return experimentCriteriaPostLaunch[experimentName] ?? false;
};

/**
 * Wrapper around the useExperiment functionality
 * Won't return whether the device/user is part of the experiment until the SDK is completely initialized
 *
 * Usage:
 *  const isEnabled = useABTest(EXPERIMENTS.EXPERIMENT_NAME);
 */
export const useABTest = (
  experimentName: ExperimentNameValue
): ExperimentStatus => {
  const { config, isLoading } =
    useExperimentWithExposureLoggingDisabled(experimentName);
  // Based on the product team's feedback, experiments will primarily
  // focus on auth users for now
  const doesMeetExperimentCriteria =
    useExperimentsCriteriaPreLaunch(experimentName);

  const doesMeetExperimentCriteriaPostLaunch =
    useExperimentsCriteriaPostLaunch(experimentName);
  if (isLoading) {
    return {
      isExperimentAvailable: false,
      groupName: "None",
    };
  }

  return configCheck(
    config,
    experimentName,
    doesMeetExperimentCriteria,
    doesMeetExperimentCriteriaPostLaunch
  );
};
