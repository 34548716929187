// Include our external dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// App Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
interface AppState {
  heroButtonsVisible: boolean;
}
const initialState: AppState = {
  heroButtonsVisible: true,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updatePDPButtonsVisible(state, action: PayloadAction<boolean>) {
      state.heroButtonsVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePDPButtonsVisible } = appSlice.actions;
