// Include our external dependencies.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import { AsyncStatus, RefinanceOffer } from "../types";

const getRefinanceOffers = dashApi
  .path("/products/refinance-offers")
  .method("get")
  .create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Personalized Offers Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|

interface OffersState {
  refinance: RefinanceOffer | null;
  refinanceStatus: AsyncStatus;
}
const initialState: OffersState = {
  refinance: null,
  refinanceStatus: "idle",
};

// Load Refinance Offer Response from the API
export const loadRefinanceOffer = createAsyncThunk(
  "offers/loadRefinanceOffer",
  async function () {
    const refinanceResponse = await getRefinanceOffers({});
    return refinanceResponse?.data?.data?.[0] || null;
  }
);

export const personalizedOffersSlice = createSlice({
  name: "personalizedOffers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRefinanceOffer.pending, (state) => {
        state.refinanceStatus = "loading";
      })
      .addCase(loadRefinanceOffer.fulfilled, (state, action) => {
        state.refinanceStatus = "loaded";
        state.refinance = action.payload;
      })
      .addCase(loadRefinanceOffer.rejected, (state) => {
        state.refinanceStatus = "failed";
        state.refinance = null;
      });
  },
});
