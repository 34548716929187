import { useDispatch, useSelector } from "react-redux";
import { translate as t } from "../../../helpers/i18n";
import { AppDispatch, RootState } from "../../../stores";
import Button from "../../atoms/button";
import BaseModal from "../modal";
import { Trans } from "react-i18next";
import { closeCreatedAccountModal } from "../../../stores/sso-user-slice";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";

interface AccountCreatedModalProps {
  show: boolean;
  module?: string | undefined;
  onClose?: () => void;
}

const AccountCreatedModal = ({
  show,
  module = "sso-account-creation-success",
  onClose,
}: AccountCreatedModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    user: { user },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (show) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: module,
      });
    }
  }, [show, module]);

  const handleClose = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: module,
      button: "close",
    });
    onClose && onClose();
    dispatch(closeCreatedAccountModal());
  };

  const handleGoToDashboard = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: module,
      button: "continue-to-dashboard",
    });

    onClose && onClose();
    dispatch(closeCreatedAccountModal());
  };

  return (
    <BaseModal
      show={show}
      onClose={handleClose}
      onCloseButtonClick={handleClose}
      title={t("account_created_modal.title")}
      titleClassName="d-xl-none text-subtitle-01 text-md-headline-07 text-typography-surface-high-emphasis"
      dialogClassName="account-created-modal-dimension"
      contentClassName="account-created-modal-dimension p-3"
      bodyClassName="px-0 pb-4 pt-5 pb-md-3 px-xl-6 pt-xl-0 pb-xl-4"
      headerClassName="p-0"
      footerClassName="justify-content-end p-0 pt-3 pb-xl-3 px-xl-6"
      className="d-flex justify-content-center"
      buttons={
        <Button
          onClick={handleGoToDashboard}
          className="w-100 w-md-auto justify-content-center"
          color="primary"
        >
          {t("account_created_modal.button")}
        </Button>
      }
    >
      <div className="d-none d-xl-block text-md-headline-04 text-typography-surface-high-emphasis pt-0 pb-3">
        {t("account_created_modal.title")}
      </div>
      <p className="text-body-02 m-0">
        <Trans
          i18nKey="account_created_modal.content_cobranded"
          values={{
            email: user?.email,
          }}
          components={{
            a: (
              <a
                target="_blank"
                rel="noreferrer"
                href={t("account_created_modal.href")}
              >
                {t("account_created_modal.link")}
              </a>
            ),
            bold: <strong />,
          }}
        />
      </p>
    </BaseModal>
  );
};

export default AccountCreatedModal;
