import { ReactComponent as StandaloneLogoLakeviewSVG } from "purple-rain/assets/standalone-logo-lakeview.svg";
import { translate as t } from "../../../helpers/i18n";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import LegalDisclaimer from "./legal-disclaimer";
import FooterBase from "./footer-base";
import useStandaloneMode from "../../../hooks/use-standalone";
import { EXPERIMENTS, useABTest } from "../../../hooks/use-ab-test";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";

const MIXPANEL_MODULE_NAME = "footer";

interface ListItem {
  translationKey: string;
  path: string;
  mixpanelButtonName?: string;
}

const PRODUCT_LIST = (includeHomeEquityLoan?: boolean): ListItem[] => [
  // Cash Out Refinance
  {
    translationKey: "layout.products_list.cash_out_refinance.title",
    path: pages.products.cashOutRefinance,
    mixpanelButtonName: "go-to-cashout-pdp",
  },
  ...(includeHomeEquityLoan
    ? [
        {
          translationKey: "layout.products_list.home_equity_loan.title",
          path: pages.products.homeEquityLoan,
          mixpanelButtonName: "go-to-heloan-pdp",
        },
      ]
    : []),
  // Get a Mortgage
  {
    translationKey: "layout.products_list.get_mortgage.title",
    path: pages.products.getAMortgage,
    mixpanelButtonName: "go-to-mortgage-pdp",
  },
  // Rate Term Refinance
  {
    translationKey: "layout.products_list.rate_term_refinance.title",
    path: pages.products.rateTermRefinance,
    mixpanelButtonName: "go-to-rtr-pdp",
  },
];

const RESOURCES_LIST: ListItem[] = [
  {
    translationKey: "layout.footer.resource_hub",
    path: pages.resourceCenter,
  },
  {
    translationKey: "layout.footer.faq_and_support",
    path: pages.frequentlyAskedQuestions,
  },
  {
    translationKey: "layout.footer.terms_and_conditions",
    path: pages.termsConditions,
  },
];

const renderItemList = (itemList: ListItem[]) => {
  const handleClick = (item: ListItem) => {
    if (item.mixpanelButtonName) {
      const link = `${window.location.origin}${item.path}`;

      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: MIXPANEL_MODULE_NAME,
        button: item.mixpanelButtonName,
        link,
      });
    }
  };

  return (
    <div className="d-flex flex-column gap-3 align-items-start">
      {itemList.map((item, index) => (
        <Link
          to={item.path}
          key={item.translationKey}
          className={`text-body-01 text-indigo-2 ${index === 0 ? "m-0" : ""}`}
          onClick={() => handleClick(item)}
        >
          {t(item.translationKey)}
        </Link>
      ))}
    </div>
  );
};

const Footer = () => {
  const isStandalone = useStandaloneMode();
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive } = useABTest(
    EXPERIMENTS.TAVANT_DIGITAL_HELOAN
  );

  if (isStandalone) return <footer className="bg-primary w-full py-2" />;

  return (
    <FooterBase includeDisclosures>
      <div className="d-flex flex-column flex-md-row w-100 gap-5">
        <div className="d-flex flex-column flex-xl-row w-100 gap-6 gap-xl-7 text-body-02">
          <div className="pe-xl-5">
            <StandaloneLogoLakeviewSVG width={250} />
          </div>
          <div className="d-flex flex-column flex-md-row w-100 gap-5">
            <div className="w-100">
              <div className="text-caption text-indigo-2 fw-bold col mb-3">
                {t("layout.footer.products_title")}
              </div>
              {renderItemList(PRODUCT_LIST(isHeloanHomeEquityLoanActive))}
            </div>
            <div className="w-100">
              <div className="text-caption text-indigo-2 fw-bold col mb-3">
                {t("layout.footer.resources_title")}
              </div>
              <div className="d-flex flex-column gap-3 align-items-start text-body-02">
                {renderItemList(RESOURCES_LIST)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="w-100 text-light-pink opacity-100 m-0" />

      {/* Legal disclaimer */}
      <LegalDisclaimer />
    </FooterBase>
  );
};

export default Footer;
