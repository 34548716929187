import { ReactComponent as AdaAccessibilitySVG } from "../../../images/ada-accessibility.svg";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import { translate as t } from "../../../helpers/i18n";

const AccessibilityAdaCompliance = () => {
  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <AdaAccessibilitySVG className="typeface-body-fine" />
      </div>
      <Link className="typeface-body-fine" to={pages.adaAccessibility}>
        {t("layout.footer.ada_accessibility.title")}
      </Link>
    </div>
  );
};

export default AccessibilityAdaCompliance;
